import axios from 'axios';

export const httpServiceApiDfe = {
    post,
    postFile,
    put,
    get,
    getBlob,
    getFile,
    del
};


async function post(pathApiDfe, body) {
    return await req({ method: 'post', pathApiDfe: pathApiDfe, data: body });
}

async function postFile(pathApiDfe, body) {
    return await reqFile({ method: 'post', pathApiDfe: pathApiDfe, data: body });
}

async function put(pathApiDfe, body) {
    const response = await req({ method: 'put', pathApiDfe: pathApiDfe, data: body });
    return await response.data;
}

// return body only ok
async function get(pathApiDfe) {
    const response = await req({ method: 'get', pathApiDfe: pathApiDfe });
    return await response.data;
}

async function getBlob(pathApiDfe, fileName) {
    const response = await reqBlob({ pathApiDfe: pathApiDfe }, fileName);
    return await response.data;
}

async function getFile(pathApiDfe, filename) {
    await downloadFile({ pathApiDfe: pathApiDfe, filename: filename });
}

async function del(pathApiDfe) {
    return await req({ method: 'delete', pathApiDfe: pathApiDfe });
}

/////////////////////////////////////////////////////////////////////////////////
async function req({ method, pathApiDfe, data }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';
    
    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    var response = await axios({
        method: method,
        url: url,
        data: data
    });
    return response;
}


async function reqBlob({ method, pathApiDfe, data }, fileName) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';
    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    });

    await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
    });

    return response;
}


// req file
async function reqFile({ method, pathApiDfe, data }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';

    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    var response = await axios({
        method: method,
        url: url,
        headers: { "Content-Type": `multipart/form-data; boundary=${data._boundary}` },
        data: data
    });
    return response;
}

// get file
async function downloadFile({ pathApiDfe, filename }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';

    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    try {
        const response = await axios({
            method: 'GET',
            url: url,
            responseType: 'blob', // 'blob' é necessário para arquivos binários
        });

        // Cria uma URL temporária para o blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));

        // Cria um link temporário para forçar o download
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', filename); // Nome do arquivo
        tempLink.click(); // Dispara o download

        // Opcional: Revoga o objeto URL para liberar memória
        window.URL.revokeObjectURL(fileURL);
    } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
        throw error;
    }
}
/////////////////////////////////////////////////////////////////////////////////