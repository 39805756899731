import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteMultipleContaReceber } from '../service';
import {
  DELETE_MULTIPLE_CONTA_RECEBER,
  DELETE_MULTIPLE_CONTA_RECEBER_PENDING,
  DELETE_MULTIPLE_CONTA_RECEBER_SUCCESS,
  DELETE_MULTIPLE_CONTA_RECEBER_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteMultipleContaReceber(action) {
  yield put({ type: DELETE_MULTIPLE_CONTA_RECEBER_PENDING, ids: action.ids });

  try {
    const a = yield call(deleteMultipleContaReceber, action.ids);

    if (a.status !== 204) {
      throw new Error('API deleteMultiple request failed');
    }
    yield put({ type: DELETE_MULTIPLE_CONTA_RECEBER_SUCCESS, ids: action.ids });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {
    yield put({ type: DELETE_MULTIPLE_CONTA_RECEBER_FAILURE });

    AlertError('Falha ao excluir os registros! Verifique se a conta não possui vínculo com outra conta gerencial ou em lançamentos no financeiro.');
  }
}

export default function* watchDeleteMultipleContaReceber() {
  yield takeLatest(DELETE_MULTIPLE_CONTA_RECEBER, sagaDeleteMultipleContaReceber)
}
