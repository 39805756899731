import * as Yup from 'yup';

const newRegistro = {
    data: '',
    competencia: '',
    numeroDocumento: '',
    conta: null,
    contaDestino: null,
    tipoMovimento: null,
    valor: 0,
    tipoEntidade: 'PESSOA',
    pessoa: null,
    motorista: null,
    funcionario: null,
    nomeEntidadeOutros: '',
    contaGerencial: null,
    centroCusto: null,
    historico: '',
    automatico: false,
};

const validation = Yup.object().shape({
    tipoMovimento: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    data: Yup.date()
        .required('Este campo é obrigatório'),
    competencia: Yup.date()
        .required('Este campo é obrigatório'),
    conta: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    tipoEntidade: Yup.string().when('tipoMovimento', {
        is: (tipoMovimento) => (tipoMovimento === 'ENTRADA' || tipoMovimento === 'SAIDA'),
        then: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório'),
    }).nullable(),
    valor: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    contaGerencial: Yup.object().when('tipoMovimento', {
        is: (tipoMovimento) => (tipoMovimento === 'ENTRADA' || tipoMovimento === 'SAIDA'),
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório")
            }),
    }).nullable(),
    historico: Yup.string().when('tipoMovimento', {
        is: (tipoMovimento) => tipoMovimento === 'ENTRADA' || tipoMovimento === 'SAIDA',
        then: Yup.string()
            .max(255, 'Campo deve ter menos do que 255 caracteres')
            .required('Este campo é obrigatório'),
        otherwise: Yup.string()
            .max(200, 'Campo deve ter menos do que 200 caracteres'),
    }).nullable(),
    numeroDocumento: Yup.string().when('tipoMovimento', {
        is: (tipoMovimento) => (tipoMovimento === 'ENTRADA' || tipoMovimento === 'SAIDA'),
        then: Yup.string()
            .max(60, 'Campo deve ter menos do que 60 caracteres'),
    }).nullable(),
    contaDestino: Yup.object().when('tipoMovimento', {
        is: (tipoMovimento) => (tipoMovimento === 'TRANSFERENCIA'),
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
    }).nullable(),
});

export const controleContaCaixa = {
    newRegistro,
    validation
}