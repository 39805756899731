import { takeLatest, put, call } from 'redux-saga/effects';

import swal from 'sweetalert';
import {
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_PENDING,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_SUCCESS,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast'
import { updateNumeroDoc, attachFiles, deleteAttachFiles} from '../service';

function* sagaUpdateNroDoc(action) {
  yield put({ type: UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_PENDING })

  try {
    let urlTipoConta = '';
    if (action.urlTipoConta === 'pagar') {
      urlTipoConta = '/contas/pagar';
    } else {
      urlTipoConta = '/contas/receber';
    }

    const contaUpdateData = {
      numeroDoc: action.data.numeroDoc,
      historico: action.data.historico,
      observacao: action.data.obs,
      tipoEntidade: action.data.tipoEntidade,
      idPessoa: (action.data.pessoa === undefined || action.data.pessoa === null) ? null : action.data.pessoa.id,
      idMotorista: (action.data.motorista === undefined || action.data.motorista === null) ? null : action.data.motorista.id,
      idFuncionario: (action.data.funcionario === undefined || action.data.funcionario === null) ? null : action.data.funcionario.id,
      nomeEntidadeOutros: action.data.nomeEntidadeOutros ?? '',
      idContaGerencial: action.data.contaGerencial.id,
      classificacao: action.data.contaGerencial.classificacao,
      idCentroCusto: action.data.centroCusto === undefined || action.data.centroCusto === null ? null : action.data.centroCusto.id,
      idIdentificador: action.data.identificador === undefined || action.data.identificador === null ? null : action.data.identificador.id,
    };
    
    if (action.urlTipoConta === 'pagar') {
      contaUpdateData.vincularPatrimonio = action.data.vincularPatrimonio ?? false;
      contaUpdateData.idPatrimonio = (action.data.vincularPatrimonio ?? false) === false ? null : action.data.patrimonio.id;
    }
    
    yield call(updateNumeroDoc, urlTipoConta, action.idConta, contaUpdateData);

    if ( action.files.filesToAttach.length > 0 ) { yield call(attachFiles, urlTipoConta, action.idConta, action.files.filesToAttach); }
    if ( action.files.filesIdToDelete.length > 0 ) { yield call(deleteAttachFiles, urlTipoConta, action.idConta, action.files.filesIdToDelete); }

    yield put({ type: UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_SUCCESS });

    swal({
      title: 'Informações da conta atualizada com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

  } catch (error) {
    yield put({ type: UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_FAILURE })

    AlertError('Falha ao atualizar as informações da conta. Tente novamente.');
  }
}

export default function* watchUpdateNroDoc() {
  yield takeLatest(UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER, sagaUpdateNroDoc)
}
