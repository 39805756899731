import React from 'react';
import LoadingScreen from 'react-loading-screen';

export const PageLoaderScreen = (props) => {
    const { loading, textLoading } = props;

    return (
        loading ? (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                zIndex: 9999
            }}>
                <LoadingScreen
                    loading={true}
                    bgColor="transparent"
                    spinnerColor="#FFF"
                    textColor="#FFF"
                    text={textLoading === undefined ? 'Aguarde! Processando...' : textLoading}
                >
                    <div /> {/* Children vazios para evitar o warning */}
                </LoadingScreen>
            </div>
        ) : null
    );
};
