import { takeLatest, put, call } from 'redux-saga/effects';
import { filterContaPagarRecorrente } from '../service';
import { storage } from '@commons/storage';
import {
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE,
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_PENDING,
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_SUCCESS,
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_FAILURE,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_CONTA_PAGAR_RECORRENTE } from '@commons/consts';

function* sagaNewFilterNextPageTransferenciaEstoque(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_PENDING });
  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let filterSaved = sessionStorage.getItem(CONST_FILTER_CONTA_PAGAR_RECORRENTE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_CONTA_PAGAR_RECORRENTE)) : null;
    
    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);
    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };
    
    const list = yield call(filterContaPagarRecorrente, idFazenda, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);
    sessionStorage.setItem(CONST_FILTER_CONTA_PAGAR_RECORRENTE, JSON.stringify(newFilter));
    
    yield put({
      type: NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {
    yield put({ type: NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_FAILURE })
  }
}

export default function* watchNewFilterNextPageTransferenciaEstoque() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE, sagaNewFilterNextPageTransferenciaEstoque)
}
