import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/nfe/emissao' };
/////////////////////////////////////////////////////////////////////////////////

export function createNFeEmissao(payload) {
  return httpService.post(args, payload);
}

export function updateNFeEmissao(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findNFeEmissao(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function filterNFeEmissao(datas, where) {
  return httpService.get({ ...args, args: `?datas=${datas}${where}` });
}

export function getTotaisNFeEmissao(datas, where) {
  return httpService.get({ ...args, args: `/totais-por-status?datas=${datas}${where}` });
}

export function deleteNFeEmissao(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function calcularTotaisNFe(nota) {
  return httpService.post({ ...args, args: `/calcularTotais` }, nota);
}

export function emitirNFeEmissao(id) {
  return httpService.post({ ...args, args: `/emitir/${id}` });
}

export function cancelarNFeEmissao(id, justificativa) {
  return httpService.postPlainText({ ...args, args: `/cancelar/${id}` }, justificativa);
}

export function corrigirCCeNFeEmissao(id, correcao) {
  return httpService.postPlainText({ ...args, args: `/cce/${id}` }, correcao);
}

export function duplicarNFeEmissao(id) {
  return httpService.get({ ...args, args: `/duplicar/${id}` });
}

export async function getPreviaNfeDanfe(idNota) {
  const pdfUrl = await httpService.getPdfModal({ ...args, args: `/previa-danfe/${idNota}` });
  return pdfUrl;
}

export function downloadZip(payload) {
  const { ambiente, emitente, mes, ano} = payload;
  return httpService.getBlob(
    {
      ...args,
      args: `/download-zip?ambiente=${ambiente}&emitente=${emitente}&mes=${mes}&ano=${ano}`,
    },
    `nfe_${mes}_${ano}.zip`,
  );
}
