import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';
import {
  CONFIG_SESSION,
  NEW_FILTER_CONTA_PAGAR_RECORRENTE,
  NEW_FILTER_CONTA_PAGAR_RECORRENTE_PEDING,
  NEW_FILTER_CONTA_PAGAR_RECORRENTE_SUCCESS,
  NEW_FILTER_CONTA_PAGAR_RECORRENTE_FAILURE,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { filterContaPagarRecorrente } from '../service';
import { CONST_FILTER_CONTA_PAGAR_RECORRENTE, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterTransferenciaEstoque(action) {
  try {
    yield put({ type: NEW_FILTER_CONTA_PAGAR_RECORRENTE_PEDING });
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    let where = '';
    let newFilter = {
      tipoEntidade: 'PESSOA',
      pessoa: null,
      motorista: null,
      funcionario: null,
      nomeEntidadeOutros: '',
      contaGerencial: null,
      centroCusto: null,
      historico: null,
      dataInicial: '',
      dataFinal: '',
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: ''
    };
    
    let filterSaved = sessionStorage.getItem(CONST_FILTER_CONTA_PAGAR_RECORRENTE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_CONTA_PAGAR_RECORRENTE)) : null;
    if (filterSaved === null || filterSaved === undefined) {
      // seta data do mes na primeira vez que entra na pagina
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));
      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
      newFilter = {
        ...newFilter,
        dataInicial,
        dataFinal,
      };
    } else {
      // se seta filtro manual
      if (action.newFilter) {
        // seta filtro indicado
        // typePeriodo = 0 -> periodo manual
        // typePeriodo = 1 -> hoje
        // typePeriodo = 2 -> esta semana
        // typePeriodo = 3 -> mes passado
        // typePeriodo = 4 -> este mes
        // typePeriodo = 5 -> proximo mes
        // typePeriodo = 6 -> este ano
        if (action.typePeriodo === undefined || action.typePeriodo === 0) {
          dataInicial = action.args.dataInicial;
          dataFinal = action.args.dataFinal;
          datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
        } else if (action.typePeriodo === 1) {
          dataInicial = formatDateYYYYMMDD(new Date(date));
          dataFinal = formatDateYYYYMMDD(new Date(date));
          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 2) {
          const primeiro = date.getDate() - date.getDay();
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));
          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 3) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));
          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 4) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));
          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 5) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));
          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 6) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));
          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }

        if (action.args.tipoEntidade !== null && action.args.tipoEntidade !== undefined && action.args.tipoEntidade !== '') {
            where = where + '&tipoEntidade=' + action.args.tipoEntidade;
            if (action.args.tipoEntidade === 'FUNCIONARIO') {
                if (action.args.funcionario !== null && action.args.funcionario !== undefined && action.args.funcionario !== '') {
                    where = where + '&funcionario=' + action.args.funcionario.id;
                }
            } else if (action.args.tipoEntidade === 'MOTORISTA') {
                if (action.args.motorista !== null && action.args.motorista !== undefined && action.args.motorista !== '') {
                    where = where + '&motorista=' + action.args.motorista.id;
                }
            } else if (action.args.tipoEntidade === 'PESSOA') {
                if (action.args.pessoa !== null && action.args.pessoa !== undefined && action.args.pessoa !== '') {
                    where = where + '&pessoa=' + action.args.pessoa.id;
                }
            } else if (action.args.tipoEntidade === 'OUTROS') {
                if (action.args.nomeEntidadeOutros !== null && action.args.nomeEntidadeOutros !== undefined && action.args.nomeEntidadeOutros !== '') {
                    where = where + '&nomeEntidadeOutros=' + action.args.nomeEntidadeOutros;
                }
            }
        }

        if (action.args.contaGerencial !== null && action.args.contaGerencial !== undefined && action.args.contaGerencial !== '') {
            where = where + '&contaGerencial=' + action.args.contaGerencial.id;
        }
        if (action.args.centroCusto !== null && action.args.centroCusto !== undefined) {
          where = where + '&centroCusto=' + action.args.centroCusto.id;
        }
        if (action.args.historico !== null && action.args.historico !== undefined && action.args.historico !== '') {
            where = where + '&historico=' + action.args.historico;
        }
        
        newFilter = {
          ...newFilter,
          ...action.args,
          dataInicial,
          dataFinal,
          where: where
        };
      } else {
        // busca filtro salvo
        datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

        if (filterSaved.tipoEntidade !== null && filterSaved.tipoEntidade !== undefined && filterSaved.tipoEntidade !== '') {
            where = where + '&tipoEntidade=' + filterSaved.tipoEntidade;
            if (filterSaved.tipoEntidade === 'FUNCIONARIO') {
                if (filterSaved.funcionario !== null && filterSaved.funcionario !== undefined && filterSaved.funcionario !== '') {
                    where = where + '&funcionario=' + filterSaved.funcionario.id;
                }
            } else if (filterSaved.tipoEntidade === 'MOTORISTA') {
                if (filterSaved.motorista !== null && filterSaved.motorista !== undefined && filterSaved.motorista !== '') {
                    where = where + '&motorista=' + filterSaved.motorista.id;
                }
            } else if (filterSaved.tipoEntidade === 'PESSOA') {
                if (filterSaved.pessoa !== null && filterSaved.pessoa !== undefined && filterSaved.pessoa !== '') {
                    where = where + '&pessoa=' + filterSaved.pessoa.id;
                }
            } else if (filterSaved.tipoEntidade === 'OUTROS') {
                if (filterSaved.nomeEntidadeOutros !== null && filterSaved.nomeEntidadeOutros !== undefined && filterSaved.nomeEntidadeOutros !== '') {
                    where = where + '&nomeEntidadeOutros=' + filterSaved.nomeEntidadeOutros;
                }
            }
        }
        if (filterSaved.contaGerencial !== null && filterSaved.contaGerencial !== undefined && filterSaved.contaGerencial !== '') {
          where = where + '&contaGerencial=' + filterSaved.contaGerencial.id;
        }
        if (filterSaved.centroCusto !== null && filterSaved.centroCusto !== undefined) {
          where = where + '&centroCusto=' + filterSaved.centroCusto.id;
        }
        if (filterSaved.historico !== null && filterSaved.historico !== undefined && filterSaved.historico !== '') {
          where = where + '&historico=' + filterSaved.historico;
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }
    
    // busca registros
    const list = yield call(filterContaPagarRecorrente, idFazenda, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);
    sessionStorage.setItem(CONST_FILTER_CONTA_PAGAR_RECORRENTE, JSON.stringify(newFilter));
    
    yield put({
      type: NEW_FILTER_CONTA_PAGAR_RECORRENTE_SUCCESS, list: list, filter: newFilter
    });
  } catch (_) {
    yield put({ type: NEW_FILTER_CONTA_PAGAR_RECORRENTE_FAILURE })
  }
}

export default function* watchNewFilterTransferenciaEstoque() {
  yield takeLatest(NEW_FILTER_CONTA_PAGAR_RECORRENTE, sagaNewFilterTransferenciaEstoque)
}
