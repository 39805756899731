
export const NEW_BAIXAR_CONTA = 'NEW_BAIXAR_CONTA'
export const NEW_BAIXAR_CONTA_SUCCESS = 'NEW_BAIXAR_CONTA_SUCCESS'

export const BAIXAR_CONTA_PAGAMENTO = 'BAIXAR_CONTA_PAGAMENTO'
export const BAIXAR_CONTA_PAGAMENTO_PENDING = 'BAIXAR_CONTA_PAGAMENTO_PENDING'
export const BAIXAR_CONTA_PAGAMENTO_SUCCESS = 'BAIXAR_CONTA_PAGAMENTO_SUCCESS'
export const BAIXAR_CONTA_PAGAMENTO_FAILURE = 'BAIXAR_CONTA_PAGAMENTO_FAILURE'

export const NEW_BAIXAR_CONTA_EM_LOTE = 'NEW_BAIXAR_CONTA_EM_LOTE'
export const NEW_BAIXAR_CONTA_EM_LOTE_SUCCESS = 'NEW_BAIXAR_CONTA_EM_LOTE_SUCCESS'

export const BAIXAR_CONTA_PAGAMENTO_EM_LOTE = 'BAIXAR_CONTA_PAGAMENTO_EM_LOTE'
export const BAIXAR_CONTA_PAGAMENTO_EM_LOTE_PENDING = 'BAIXAR_CONTA_PAGAMENTO_EM_LOTE_PENDING'
export const BAIXAR_CONTA_PAGAMENTO_EM_LOTE_SUCCESS = 'BAIXAR_CONTA_PAGAMENTO_EM_LOTE_SUCCESS'
export const BAIXAR_CONTA_PAGAMENTO_EM_LOTE_FAILURE = 'BAIXAR_CONTA_PAGAMENTO_EM_LOTE_FAILURE'
