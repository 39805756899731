import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/contas/recorrente/pagar' };
/////////////////////////////////////////////////////////////////////////////////

export function createContaPagarRecorrente(payload) {
  return httpService.post(args, payload);
}

export function updateContaPagarRecorrente(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findContaPagarRecorrente(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function alterarStatusContaRecorrente(id) {
  return httpService.put({ ...args, args: `/alterarStatus/${id}` });
}

export function gerarContaAgoraContaRecorrente(id) {
  return httpService.put({ ...args, args: `/gerarContaAgora/${id}` });
}

export function deleteContaPagarRecorrente(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function listContaPagarRecorrente(param) {
  return httpService.get({ ...args, args: param });
}

export function filterContaPagarRecorrente(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&datas=${datas}${where}` });
}
