


import watchBaixarPagamento from './saga/baixar';
import watchNewBaixarConta from './saga/new';
import watchBaixarPagamentoEmLote from './saga/baixarEmLote';
import watchNewBaixarContaEmLote from './saga/newEmLote';

export const baixaContaSaga = [
    watchBaixarPagamento(),
    watchNewBaixarConta(),
    watchBaixarPagamentoEmLote(),
    watchNewBaixarContaEmLote(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
