import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { estornarMultiplePagamento } from '../service';
import {
  CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO,
  CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO_PENDING,
  CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO_SUCCESS,
  CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast';

function* sagaEstornarMultiplePagamentoContaPagar(action) {
  yield put({ type: CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO_PENDING });

  try {
    const payload = action.data.map(item => ({
      contaId: item.idConta,
      pagamentoId: item.id
    }));

    yield call(estornarMultiplePagamento, payload);
    yield put({ type: CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO_SUCCESS });

    swal({
      title: 'Pagamento estornado com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

  } catch (error) {
    yield put({ type: CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO_FAILURE });
    AlertError('Falha ao excluir o registro! Verifique se a conta não possui vínculo com outra conta gerencial ou em lançamentos no financeiro.');
  }
}

export default function* watchEstornarMultiplePagamentoContaPagar() {
  yield takeLatest(CONTA_PAGAR_ESTORNAR_MULTIPLE_PAGAMENTO, sagaEstornarMultiplePagamentoContaPagar);
}
