import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons';
import { downloadZip } from '../service';
import {
  DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA,
  DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_PENDING,
  DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_SUCCESS,
  DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_FAILURE,
  CONFIG_SESSION,
} from '@handler';
import { AlertError, AlertSuccess, AlertWarning } from '@components/common/AlertToast';

function* sagaDownloadZipMonitoramentoNFeCompra(action) {
  yield put({ type: DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_PENDING });

  try {
    var dominio = JSON.parse(storage.get(CONFIG_SESSION)).schemaSelecionado;

    let emitente = ''; 
    if (action.data.emitente.tipoPessoa === "JURIDICA") {
        emitente = action.data.emitente.cnpj.replace(/\D/g, '');
    } else {
        emitente = action.data.emitente.cpf.replace(/\D/g, '');
    }

    const payload = {
        dominio,
        emitente,
        mes: action.data.mes,
        ano: action.data.ano,
    }

    yield call(downloadZip, payload);
    yield put({ type: DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_SUCCESS});

    AlertSuccess('Arquivos NFe baixados com sucesso!');
  } catch (error) {
    yield put({ type: DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_FAILURE });

    if (error.response && error.response.status === 404) {
      AlertWarning('Nenhum arquivo encontrado para o mês e ano informado.');
    } else {
      AlertError('Falha ao baixar arquivos da NFe! Tente novamente mais tarde...');
    }
  }
}

export default function* watchDownloadZipMonitoramentoNFeCompra() {
  yield takeLatest(DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA, sagaDownloadZipMonitoramentoNFeCompra)
}
