import * as Yup from 'yup';
import moment from 'moment';

const newRegistro = {
    status: true,
    propriedade: null,
    tipoRecorrencia: 'MENSALMENTE',
    gerarDespesa: 'CINCO_DIAS_ANTES',
    quantasVezes: 'PARA_SEMPRE',
    proximoVencimento: "",
    competencia: '',
    valorTotal: 0,
    contaGerencial: null,
    centroCusto: null,
    historico: "",
    numeroDoc: "",
    tipoEntidade: 'PESSOA',
    pessoa: null,
    motorista: null,
    funcionario: null,
    nomeEntidadeOutros: '',
    obs: "",
    dataTermino: "",
    quantasVezesNumero: null,
    vincularPatrimonio: false,
    patrimonio: null,
    lancarDuplicataPaga: false,
    gerarProximaFaturaAgora: true,
    tipoPagamento: null,
    pgtoContaCaixa: null,
    pgtoContaBanco: null,
    apropriarCustoSafra: false,
    despDireta: null,
    rateioSafras: []
};

const validation = Yup.object().shape({
    tipoRecorrencia: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    gerarDespesa: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    quantasVezes: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    dataTermino: Yup.string().when('quantasVezes', {
        is: (quantasVezes) => quantasVezes === 'ATE_A_DATA',
        then: Yup.string().test('is-date', 'Este campo é obrigatório', (value) => {
            if (value) {
                const date = new Date(value);
                return !isNaN(date.getTime());
            }
            return false;
        }).nullable(),
    }),
    quantasVezesNumero: Yup.number().when('quantasVezes', {
        is: (quantasVezes) => quantasVezes === 'ALGUMAS_VEZES',
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .positive('Por Favor, informe um valor maior que zero')
            .required('Este campo é obrigatório'),
    }).nullable(),
    proximoVencimento: Yup.date()
        .required('Este campo é obrigatório')
        .min(moment().startOf('day').toDate(), 'A data deve ser igual ou posterior à data atual'),
    competencia: Yup.date()
        .required('Este campo é obrigatório'),
    tipoEntidade: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    contaGerencial: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    historico: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    valorTotal: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    numeroDoc: Yup.string()
        .max(20, 'Campo deve ter menos do que 20 caracteres'),
    obs: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    patrimonio: Yup.object().when('vincularPatrimonio', {
        is: (vincularPatrimonio) => vincularPatrimonio === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    tipoPagamento: Yup.string().when(['lancarDuplicataPaga'], {
        is: (lancarDuplicataPaga) => lancarDuplicataPaga === true,
        then: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório')
    }).nullable(),
    pgtoContaCaixa: Yup.object().when(['lancarDuplicataPaga', 'tipoPagamento'], {
        is: (lancarDuplicataPaga, tipoPagamento) => lancarDuplicataPaga === true
            && tipoPagamento !== undefined && tipoPagamento !== null && tipoPagamento === 'DINHEIRO',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    pgtoContaBanco: Yup.object().when(['lancarDuplicataPaga', 'tipoPagamento'], {
        is: (lancarDuplicataPaga, tipoPagamento) => lancarDuplicataPaga === true
            && tipoPagamento !== undefined && tipoPagamento !== null && tipoPagamento !== 'DINHEIRO',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    despDireta: Yup.object().when('apropriarCustoSafra', {
        is: (apropriarCustoSafra) => apropriarCustoSafra === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    rateioSafras: Yup.array()
        .of(
            Yup.object().shape({
                safra: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                percentualRateio: Yup.number()
                    .positive('Por Favor, informe um valor maior que zero')
                    .max(100, 'Máximo 100%')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required('Este campo é obrigatório'),
            })
        ),
});

export const contaPagarRecorrente = {
    newRegistro,
    validation
}