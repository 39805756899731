import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import { CONST_ROWS_PER_PAGE } from '@commons/consts';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Delete } from '@components/form'

import './Table.css'

class TableReducer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: CONST_ROWS_PER_PAGE,
            first: 0,
            selectedItems: [],
            dropdownOpen: false
        };
    }

    onPage = async (event) => {
        await this.paginate({ rows: event.rows, page: event.page });
        this.setState({ rows: event.rows, first: event.first });
    }

    paginate = async ({ rows, page }) => {
        await this.props.filterNextPage(rows, page);
    }

    // Função chamada quando a seleção de itens muda
    onSelectionChange = (e) => {
        this.setState({ selectedItems: e.value });
    };

    handleBatchAction = (action) => {
        const { selectedItems } = this.state;
        if (!selectedItems.length) return;

        if (this.props.batchActions && this.props.batchActions[action]) {
            this.props.batchActions[action](selectedItems);
        }

        this.setState({ selectedItems: [] });
    };

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    render() {
        const refDt = React.createRef();
        const { reducer, table, filter, indexFieldMsgDelete, keyInput } = this.props;
        const { editLink, onRemove, onEdit, enableSelection, batchActions, disableCondition } = this.props;
        const { selectedItems, dropdownOpen } = this.state;

        const paginatorLeft = <React.Fragment />;

        return (
            <>
                {enableSelection && (
                    <div className="mb-3">
                        <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
                            <DropdownToggle caret color="primary" disabled={selectedItems.length === 0}>
                                Ações em Lote
                                <i className="fas fa-caret-down ml-2"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                {batchActions && Object.keys(batchActions).map(actionKey => {
                                    const isDisabled = disableCondition[actionKey] 
                                        ? disableCondition[actionKey](selectedItems) 
                                        : false;
                                        
                                    return (
                                        <DropdownItem key={actionKey} onClick={() => this.handleBatchAction(actionKey)} disabled={isDisabled}>
                                            {actionKey}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                )}

                {table && <DataTable
                    key={'id_' + keyInput}
                    ref={refDt}
                    value={table.content}
                    paginator={true}
                    lazy={true}
                    paginatorLeft={paginatorLeft}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} do total de {totalRecords} registros"
                    rows={this.state.rows}
                    rowsPerPageOptions={[5, 10, 50, 100, 500]}
                    className='table table-striped my-4 w-100 p-datatable-sm'
                    totalRecords={table.totalElements}
                    autoLayout={true}
                    first={this.state.first}
                    onPage={this.onPage}
                    emptyMessage="Nenhum registro encontrado"
                    csvSeparator=';'
                    scrollable={this.props.scrollable}
                    selection={selectedItems}
                    onSelectionChange={this.onSelectionChange} 
                    selectionMode='checkbox'
                >
                    {enableSelection && <Column selectionMode="multiple" style={{ width: '45px' }} />}

                    {this.props.children}

                    {
                        <Column style={{ textAlign: 'center', width: this.props.actionWidth, display: this.props.renderActions ? undefined : 'none' }}
                            body={(rowData, column) => {
                                return (
                                    <div>

                                        {this.props.renderEdit &&
                                            <Link to={`${editLink}${rowData.id}`}>
                                                <Button outline color='white' className='btn btn-warning btn-sm'
                                                    onClick={() => onEdit(rowData.id)}
                                                    style={{ marginRight: '5px' }}>
                                                    <i className='fas fa-pencil-alt' />
                                                </Button>
                                            </Link>
                                        }

                                        {this.props.renderDelete &&
                                            <Delete registro={rowData} remove={onRemove}
                                                removed={reducer.removed} indexFieldMsgDelete={indexFieldMsgDelete} />
                                        }
                                    </div>
                                );
                            }} />
                    }
                </DataTable>
                }
            </>
        )

    }
}

TableReducer.defaultProps = {
    keyInput: 'tableSearchLazy',
    renderActions: true,
    renderEdit: true,
    renderDelete: true,
    actionWidth: '15%',
    scrollable: false,
    indexFieldMsgDelete: 0,
    enableSelection: false,
    batchActions: {},
    disableCondition: {},
    onPage: () => { },
    onRemove: () => { },
    onEdit: () => { },
    init: () => { },
};

export { TableReducer };
