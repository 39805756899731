import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/ativColheita' };
/////////////////////////////////////////////////////////////////////////////////

export function createColheita(payload) {
  return httpService.post(args, payload);
}

export function updateColheita(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listColheita(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function listColheitaTable(param) {
  return httpService.get({ ...args, args: param });
}

export function findColheita(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteColheita(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getTotalProducaoGeral(idPropriedade, idSafra, datas, where) {
  return httpService.get({...args, args: '/producaoColheita/' + idPropriedade + '/' + idSafra + `?datas=${datas}${where}`});
}

export function getGraficoProdutividadePorTalhao(idPropriedade, idSafra, datas, where) {
  return httpService.get({...args, args: '/grafico-produtividade-por-talhao/' + idPropriedade + '/' + idSafra + `?datas=${datas}${where}`});
}

export function getRelatorioColheita(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.getPdf({ ...args, args: `/relatorioColheita/pdf?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function getRelatorioColheitaExcel(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.getFileExcel({ ...args, args: `/relatorioColheita/excel?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function getGraficoAreaColhida(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.get({ ...args, args: `/grafico-area-colhida?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function getGraficoTotalColhido(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.get({ ...args, args: `/grafico-total-colhido?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}


