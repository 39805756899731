import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import { storage } from '@commons';

import {
  romaneioColheita,
  CONFIG_SESSION,
  NEW_ROMANEIO_COLHEITA,
  NEW_ROMANEIO_COLHEITA_SUCCESS,
} from '@handler';

function* sagaNewRomaneioColheita(action) {

  yield put({
    type: NEW_ROMANEIO_COLHEITA_SUCCESS, registro: {
      ...romaneioColheita.newRegistro,
      status: 'EM_PROGRESSO',
      data: moment().startOf('day').format('YYYY-MM-DD'),
      cultura: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura,
    }
  });

}

export default function* watchNewRomaneioColheita() {
  yield takeLatest(NEW_ROMANEIO_COLHEITA, sagaNewRomaneioColheita)
}
