export const NEW_FILTER_MONITORAMENTO_NFE_COMPRA = 'NEW_FILTER_MONITORAMENTO_NFE_COMPRA'
export const NEW_FILTER_MONITORAMENTO_NFE_COMPRA_PENDING = 'NEW_FILTER_MONITORAMENTO_NFE_COMPRA_PENDING'
export const NEW_FILTER_MONITORAMENTO_NFE_COMPRA_SUCCESS = 'NEW_FILTER_MONITORAMENTO_NFE_COMPRA_SUCCESS'
export const NEW_FILTER_MONITORAMENTO_NFE_COMPRA_FAILURE = 'NEW_FILTER_MONITORAMENTO_NFE_COMPRA_FAILURE'

export const NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA = 'NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA'
export const NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_PENDING = 'NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_PENDING'
export const NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_SUCCESS = 'NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_FAILURE = 'NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_FAILURE'

export const GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_PENDING = 'GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_PENDING'
export const GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_SUCCESS = 'GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_SUCCESS'
export const GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_FAILURE = 'GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_FAILURE'

export const IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA = 'IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA'
export const IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING = 'IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING'
export const IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS = 'IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS'
export const IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE = 'IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE'

export const VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA = 'VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA'
export const VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING = 'VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING'
export const VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS = 'VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS'
export const VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE = 'VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE'

export const DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA = 'DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA'
export const DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_PENDING = 'DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_PENDING'
export const DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_SUCCESS = 'DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_SUCCESS'
export const DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_FAILURE = 'DOWNLOAD_ZIP_MONITORAMENTO_NFE_COMPRA_FAILURE'
