import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createPlanejamento, createPlanejamentoMulti, updatePlanejamento } from '../service';
import {
  CONFIG_SESSION,
  CREATE_PLANEJAMENTO,
  CREATE_PLANEJAMENTO_PENDING,
  CREATE_PLANEJAMENTO_SUCCESS,
  CREATE_PLANEJAMENTO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast';

import { formatDateDDMMYYYY, formatJsonRemoveEmptyOrNull, isDateValidVerifyDateBetween } from '@components/common/format';
function* sagaCreatePlanejamento(action) {
  yield put({ type: CREATE_PLANEJAMENTO_PENDING });

  try {
    const safraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva;
    const { dataInicial: dateFrom, dataFinal: dateTo } = safraAtiva;
    const dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_PLANEJAMENTO_FAILURE, registro: action.data });
      AlertWarning(`Data informada fora do período da safra ativa. Período deve ser entre ${formatDateDDMMYYYY(dateFrom)} até ${formatDateDDMMYYYY(dateTo)}!`);
    } else {
      if (action.data.replicarLancamento) {
        let procedimentosASalvar = []

        for (let index = 0; index < action.data.talhoesList.length; index++) {
          let procedimentoTalhao = {
            ...action.data,
            'forJson': 'AtivPlanejamento',
            'sync': false,
            propriedade: {
              id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
            }
          };
          procedimentoTalhao.talhao = { ...action.data.talhoesList[index] }

          if (procedimentoTalhao.talhao.talhao !== undefined && procedimentoTalhao.talhao.talhao !== null) {
            procedimentoTalhao.talhao = procedimentoTalhao.talhao.talhao;
          }

          procedimentoTalhao.aplicacoes = [...action.data.aplicacoes]

          let custoTotalProdutosInsumos = 0;
          let areaTalhao = action.data.talhoesList[index].area;

          for (let indexAplic = 0; indexAplic < procedimentoTalhao.aplicacoes.length; indexAplic++) {
            let { custoTotalHa, custoTotalTalhao, estoqueABaixar } = calcularAplicacao(procedimentoTalhao.aplicacoes[indexAplic], areaTalhao, safraAtiva);
            Object.assign(procedimentoTalhao.aplicacoes[indexAplic], { areaTalhao, custoTotalHa, custoTotalTalhao, estoqueABaixar });

            procedimentoTalhao.aplicacoes[indexAplic] = {
              ...procedimentoTalhao.aplicacoes[indexAplic],
              areaTalhao: areaTalhao,
              custoTotalHa: custoTotalHa,
              custoTotalTalhao: custoTotalTalhao,
            }

            custoTotalProdutosInsumos += custoTotalTalhao;
          }
          procedimentoTalhao.custoTotalProdutosInsumos = custoTotalProdutosInsumos;

          procedimentosASalvar.push(procedimentoTalhao)
        }

        for (let index = 0; index < procedimentosASalvar.length; index++) {
          delete procedimentosASalvar[index].replicarLancamento;
          delete procedimentosASalvar[index].talhoesList;

          procedimentosASalvar[index].custoTotalTalhao = (procedimentosASalvar[index].custoTotalProdutosInsumos + procedimentosASalvar[index].custoTotalMaquinarios + procedimentosASalvar[index].custoTotalFuncionarios);
          procedimentosASalvar[index].custoTotalHa = (procedimentosASalvar[index].custoTotalTalhao / (procedimentosASalvar[index].talhao?.area ?? 0));
        }

        yield call(createPlanejamentoMulti, procedimentosASalvar);

        yield put({ type: CREATE_PLANEJAMENTO_SUCCESS })

        history.push('/agricultura/planejamento');

        AlertSuccess('Registro salvo com sucesso!');
      } else {
        // Cálculo em uma cópia limpa de action.data
        const singlePlanejamento = {
          ...action.data,
          talhao: action.data.talhao.talhao || action.data.talhao, 
        };
        let custoTotalProdutosInsumos = 0;

        for (let aplicacao of singlePlanejamento.aplicacoes) {
          let areaTalhao = aplicacao.areaTalhao;
          
          let { custoTotalHa, custoTotalTalhao, estoqueABaixar } = calcularAplicacao(aplicacao, areaTalhao, safraAtiva);
          custoTotalProdutosInsumos += custoTotalTalhao;

          Object.assign(aplicacao, { areaTalhao, custoTotalHa, custoTotalTalhao, estoqueABaixar });
        }

        // Atualiza os totais sem alterar a estrutura original de action.data
        singlePlanejamento.custoTotalProdutosInsumos = custoTotalProdutosInsumos;
        singlePlanejamento.custoTotalTalhao = custoTotalProdutosInsumos + singlePlanejamento.custoTotalMaquinarios + singlePlanejamento.custoTotalFuncionarios;
        singlePlanejamento.custoTotalHa = singlePlanejamento.talhao?.area ? singlePlanejamento.custoTotalTalhao / singlePlanejamento.talhao.area : 0;

        if (singlePlanejamento.id === undefined || singlePlanejamento.id === null) {
          yield call(createPlanejamento, formatJsonRemoveEmptyOrNull({
            ...singlePlanejamento,
            'forJson': 'AtivPlanejamento',
            'sync': false,
            propriedade: { id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id },
          }));
        } else {
          yield call(updatePlanejamento, singlePlanejamento);
        }

        yield put({ type: CREATE_PLANEJAMENTO_SUCCESS, registro: singlePlanejamento });
        history.push('/agricultura/planejamento');

        AlertSuccess('Registro salvo com sucesso!');
      }
    }
  } catch (error) {
    yield put({ type: CREATE_PLANEJAMENTO_FAILURE, registro: action.data });
    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

function calcularAplicacao(aplicacao, areaTalhao, safraAtiva) {
  let formaLanc = aplicacao.configLancAgricultura ? aplicacao.configLancAgriculturaTipo : safraAtiva.cultura.formaLancPlantio;
  let custoTotalHa = 0;
  let custoTotalTalhao = 0;
  let estoqueABaixar = 0;

  let custoNota = aplicacao.custoProduto / aplicacao.qtdConversaoAplicacao;
  if (formaLanc === 'POR_HA') {
    custoTotalHa = aplicacao.dosagemHa * custoNota;
    custoTotalTalhao = custoTotalHa * areaTalhao;
  } else {
    custoTotalTalhao = aplicacao.dosagemHa * custoNota;
    custoTotalHa = custoTotalTalhao / areaTalhao;
  }

  if (aplicacao.movimentaEstoque) {
    estoqueABaixar = formaLanc === 'POR_HA' ? aplicacao.dosagemHa * areaTalhao * aplicacao.qtdConversaoEstoque : aplicacao.dosagemHa * aplicacao.qtdConversaoEstoque;
  }

  return { custoTotalHa, custoTotalTalhao, estoqueABaixar };
}


export default function* watchCreatePlanejamento() {
  yield takeLatest(CREATE_PLANEJAMENTO, sagaCreatePlanejamento)
}
