import { takeLatest, put, call } from 'redux-saga/effects';

import {
  GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_SUCCESS,
  GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_FAILURE,
  GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_PENDING,
  GET_RELATORIO_ROMANEIO_COLHEITA_ITEM
} from '../actionTypes';
import { getRelatorioRomaneioColheitaItem } from '../service';

function* sagaRelatorioRomaneioColheitaItem(action) {
  yield put({ type: GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_PENDING })

  try {
    yield call(getRelatorioRomaneioColheitaItem, action.id)

    yield put({
      type: GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_SUCCESS
    });
  } catch (error) {
    yield put({ type: GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchRelatorioRomaneioColheitaItem() {
  yield takeLatest(GET_RELATORIO_ROMANEIO_COLHEITA_ITEM, sagaRelatorioRomaneioColheitaItem)
}