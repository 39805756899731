import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fluxoCaixa' };
/////////////////////////////////////////////////////////////////////////////////

export function getRelatorioFluxoCaixa(idPropriedade, data) {
  return httpService.post({ ...args, args: `/gerar-relatorio/${idPropriedade}` }, data);
}

export function getGraficoFluxoCaixa(idPropriedade, data) {
  return httpService.post({ ...args, args: `/gerar-grafico/${idPropriedade}` }, data);
}

export function getFluxoCaixaPlanoContasAnalitco(idPropriedade, datas, tipoFluxo, whereCentroCusto) {
  return httpService.get({ ...args, args: `/relatorioFluxoCaixaPlanoContasTodasContas?propriedade=${idPropriedade}&datas=${datas}&tipoFluxo=${tipoFluxo}${whereCentroCusto}` });
}

export function getFluxoCaixaPlanoContasSintetico(idPropriedade, datas, tipoFluxo, whereCentroCusto) {
return httpService.get({ ...args, args: `/relatorioFluxoCaixaPlanoContasSintetico?propriedade=${idPropriedade}&datas=${datas}&tipoFluxo=${tipoFluxo}${whereCentroCusto}` });
}

export function getFluxoCaixaPlanoContasAnalitcoPdf(idPropriedade, datas, tipoFluxo, whereCentroCusto) {
return httpService.getPdf({ ...args, args: `/relatorioFluxoCaixaPlanoContasTodasContas/pdf?propriedade=${idPropriedade}&datas=${datas}&tipoFluxo=${tipoFluxo}${whereCentroCusto}` });
}

export function getFluxoCaixaPlanoContasSinteticoPdf(idPropriedade, datas, tipoFluxo, whereCentroCusto) {
return httpService.getPdf({ ...args, args: `/relatorioFluxoCaixaPlanoContasSintetico/pdf?propriedade=${idPropriedade}&datas=${datas}&tipoFluxo=${tipoFluxo}${whereCentroCusto}` });
}
