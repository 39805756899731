import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_CENTRO_CUSTO_CADASTRO_RAPIDO,
    CANCEL_CENTRO_CUSTO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelCentroCustoCadastroRapido() {
  yield put({ type: CANCEL_CENTRO_CUSTO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelCentroCustoCadastroRapido() {
  yield takeLatest(CANCEL_CENTRO_CUSTO_CADASTRO_RAPIDO, sagaCancelCentroCustoCadastroRapido)
}