
export const CONST_FILTER_CONTAS_PAGAR = 'FILTER_CONTAS_PAGAR';
export const CONST_FILTER_CONTAS_RECEBER = 'FILTER_CONTAS_RECEBER';
export const CONST_FILTER_CONTA_PAGAR_RECORRENTE = 'CONST_FILTER_CONTA_PAGAR_RECORRENTE';
export const CONST_FILTER_FLUXO_CAIXA = 'CONST_FILTER_FLUXO_CAIXA';
export const CONST_FILTER_MOVTO_BANCO = 'CONST_FILTER_MOVTO_BANCO';
export const CONST_FILTER_MOVTO_CAIXA = 'CONST_FILTER_MOVTO_CAIXA';

export const CONST_FILTER_MONITORAMENTO_NFE_COMPRA = 'FILTER_MONITORAMENTO_NFE_COMPRA';
export const CONST_FILTER_NFE_EMISSAO = 'CONST_FILTER_NFE_EMISSAO';

export const CONST_FILTER_PLANTIO_MANEJO = 'CONST_FILTER_PLANTIO_MANEJO';
export const CONST_FILTER_PLANEJAMENTO = 'CONST_FILTER_PLANEJAMENTO';
export const CONST_FILTER_COLHEITA = 'CONST_FILTER_COLHEITA';
export const CONST_FILTER_ROMANEIO_COLHEITA = 'CONST_FILTER_ROMANEIO_COLHEITA';
export const CONST_FILTER_AGRIC_EST_BAIXA_MANUAL = 'CONST_FILTER_AGRIC_EST_BAIXA_MANUAL';

export const CONST_FILTER_CONTA_AGRARE = 'CONST_FILTER_CONTA_AGRARE';

export const CONST_FILTER_IMPLANTACAO_ESTOQUE = 'CONST_FILTER_IMPLANTACAO_ESTOQUE';
export const CONST_FILTER_TRANSFERENCIA_ESTOQUE = 'CONST_FILTER_TRANSFERENCIA_ESTOQUE';

export const CONST_FILTER_ENTRADA_ESTOQUE = 'CONST_FILTER_ENTRADA_ESTOQUE';

export const CONST_FILTER_VENDA_PRODUCAO_AGRICOLA = 'CONST_FILTER_VENDA_PRODUCAO_AGRICOLA';

export const CONST_FILTER_NOTIFICATION = 'CONST_FILTER_NOTIFICATION';

export const CONST_FILTER_PATRIMONIO_MANUTENCAO = "CONST_FILTER_PATRIMONIO_MANUTENCAO";
export const CONST_FILTER_PATRIMONIO_ABASTECIMENTO = "CONST_FILTER_PATRIMONIO_ABASTECIMENTO";

export const CONST_ROWS_PER_PAGE = 50;

export const IDENTIFICATION_CODE_WL_AGRARE = '959dc425-0995-41e2-85b6-e3a66182c077';

export const MIXPANEL_TOKEN = '46a73b93f3ab7c8d6cf7463cddba432d'; // Prod
export const AMBIENTE_DEFAULT_NFE = 'PRODUCAO'; // Prod
// export const MIXPANEL_TOKEN = 'a0562b50506d9e5b56c1f6d975018d3f'; // Homolog
// export const AMBIENTE_DEFAULT_NFE = 'HOMOLOGACAO'; // Homolog

export const TEXT_COMPANY_SLOGAN = 'Agrare - Tecnologia e Gestão no Campo';
export const TEXT_COMPANY_NAME = 'Agrare';
export const TEXT_COMPANY_ONFARM = 'Agrare';
// export const TEXT_COMPANY_SLOGAN = 'SBS Connect - Conectando Você ao Futuro!';
// export const TEXT_COMPANY_NAME = 'SBS Connect';
// export const TEXT_COMPANY_ONFARM = 'SBS On Farm';


export function clearFilters() {
    sessionStorage.setItem(CONST_FILTER_CONTAS_PAGAR, null);
    sessionStorage.setItem(CONST_FILTER_CONTAS_RECEBER, null);
    sessionStorage.setItem(CONST_FILTER_CONTA_PAGAR_RECORRENTE, null);
    sessionStorage.setItem(CONST_FILTER_FLUXO_CAIXA, null);
    sessionStorage.setItem(CONST_FILTER_MOVTO_BANCO, null);
    sessionStorage.setItem(CONST_FILTER_MOVTO_CAIXA, null);
    sessionStorage.setItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA, null);
    sessionStorage.setItem(CONST_FILTER_NFE_EMISSAO, null);
    sessionStorage.setItem(CONST_FILTER_PLANTIO_MANEJO, null);
    sessionStorage.setItem(CONST_FILTER_PLANEJAMENTO, null);
    sessionStorage.setItem(CONST_FILTER_COLHEITA, null);
    sessionStorage.setItem(CONST_FILTER_ROMANEIO_COLHEITA, null);
    sessionStorage.setItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL, null);
    sessionStorage.setItem(CONST_FILTER_CONTA_AGRARE, null);
    sessionStorage.setItem(CONST_FILTER_IMPLANTACAO_ESTOQUE, null);
    sessionStorage.setItem(CONST_FILTER_TRANSFERENCIA_ESTOQUE, null);
    sessionStorage.setItem(CONST_FILTER_ENTRADA_ESTOQUE, null);
    sessionStorage.setItem(CONST_FILTER_VENDA_PRODUCAO_AGRICOLA, null);
    sessionStorage.setItem(CONST_FILTER_NOTIFICATION, null);
    sessionStorage.setItem(CONST_FILTER_PATRIMONIO_MANUTENCAO, null);
}