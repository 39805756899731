import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  nfeEmissao,
  NEW_NFE_EMISSAO,
  NEW_NFE_EMISSAO_SUCCESS,
} from '@handler';
import { AMBIENTE_DEFAULT_NFE } from '@commons/consts';

function* sagaNewNFeEmissao(action) {
  yield put({
    type: NEW_NFE_EMISSAO_SUCCESS, registro:
    {
      ...nfeEmissao.newRegistro,
      ambiente: AMBIENTE_DEFAULT_NFE, //(action.config === null || action.config === undefined) ? 'HOMOLOGACAO' : action.config.nfeAmbiente,
      tipoOperacao: action.tipoOperacao ?? 'S',
      data: moment().format('YYYY-MM-DD HH:mm:ss'),
    }
  });
}

export default function* watchNewNFeEmissao() {
  yield takeLatest(NEW_NFE_EMISSAO, sagaNewNFeEmissao)
}
