import { takeLatest, put, call } from 'redux-saga/effects';

import { downloadZip } from '../service';
import {
  DOWNLOAD_ZIP_NFE_EMISSAO,
  DOWNLOAD_ZIP_NFE_EMISSAO_PENDING,
  DOWNLOAD_ZIP_NFE_EMISSAO_SUCCESS,
  DOWNLOAD_ZIP_NFE_EMISSAO_FAILURE
} from '../actionTypes';
import { AlertError, AlertSuccess, AlertWarning } from '@components/common/AlertToast';
import { AMBIENTE_DEFAULT_NFE } from '@commons/consts';

function* sagaDownloadZipNFeEmissao(action) {
  yield put({ type: DOWNLOAD_ZIP_NFE_EMISSAO_PENDING });

  try {
    let emitente = ''; 
    if (action.data.emitente.tipoPessoa === "JURIDICA") {
        emitente = action.data.emitente.cnpj.replace(/\D/g, '');
    } else {
        emitente = action.data.emitente.cpf.replace(/\D/g, '');
    }

    const payload = {
        ambiente: AMBIENTE_DEFAULT_NFE,
        emitente: emitente,
        mes: action.data.mes,
        ano: action.data.ano,
    }

    yield call(downloadZip, payload);
    yield put({ type: DOWNLOAD_ZIP_NFE_EMISSAO_SUCCESS});

    AlertSuccess('Arquivos NFe baixados com sucesso!');
  } catch (error) {
    yield put({ type: DOWNLOAD_ZIP_NFE_EMISSAO_FAILURE });

    if (error.response && error.response.status === 404) {
      AlertWarning('Nenhum arquivo encontrado para o mês e ano informado.');
    } else {
      AlertError('Falha ao baixar arquivos da NFe! Tente novamente mais tarde...');
    }
  }
}

export default function* watchDownloadZipNFeEmissao() {
  yield takeLatest(DOWNLOAD_ZIP_NFE_EMISSAO, sagaDownloadZipNFeEmissao)
}
