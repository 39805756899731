import * as Yup from 'yup';

const newRegistro = {
    status: 'EM_PROGRESSO',
    data: '',
    dataFim: '',
    obs: '',
    safra: null,
    talhao: null,
    cultura: null,
    localEstoque: null,
    veiculo: null,
    motorista: null,
    transportadora: null,
    funcionario: null,
    romaneio: '',
    nroNfe: '',
    obs: '',
    itens: []
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    talhao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    localEstoque: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    romaneio: Yup.string()
        .max(50, 'Campo deve ter menos do que 50 caracteres'),
    nroNfe: Yup.string()
        .max(50, 'Campo deve ter menos do que 50 caracteres'),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const romaneioColheita = {
    newRegistro,
    validation
}