import React from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import './Table.css'
import { CONST_ROWS_PER_PAGE } from '@commons/consts';


class TableNoLazy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: props.rows,
            selectedItems: [],
            dropdownOpen: false
        };
    }

    onSelectionChange = (e) => {
        this.setState({ selectedItems: e.value });
    };

    handleBatchAction = (action) => {
        const { selectedItems } = this.state;
        if (!selectedItems.length) return;

        if (this.props.batchActions && this.props.batchActions[action]) {
            this.props.batchActions[action](selectedItems);
        }

        this.setState({ selectedItems: [] });
    };

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    render() {

        const refDt = React.createRef();
        const { paginator, lazy, enableSelection, batchActions, disableCondition } = this.props;
        const { selectedItems, dropdownOpen } = this.state;

        const paginatorLeft = <React.Fragment />;
            // showBtnExport ? ((this.props.list === undefined || this.props.list === null || this.props.list.empty) ? <React.Fragment /> : 
            
            //     <Button type="button" className="btn btn-dark" onClick={() => { refDt.current.exportCSV(); }}>
            //     <i className='fas fa-file-export' /> Exportar (.csv)
            // </Button>
            
            // ) : <div></div>;

        return (
            <>
                {enableSelection && (
                    <div className="mb-3">
                        <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
                            <DropdownToggle caret color="primary" disabled={selectedItems.length === 0}>
                                Ações em Lote
                                <i className="fas fa-caret-down ml-2"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                {batchActions && Object.keys(batchActions).map(actionKey => {
                                    const isDisabled = disableCondition[actionKey] 
                                        ? disableCondition[actionKey](selectedItems) 
                                        : false;
                                        
                                    return (
                                        <DropdownItem key={actionKey} onClick={() => this.handleBatchAction(actionKey)} disabled={isDisabled}>
                                            {actionKey}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                )}

                {this.props.list &&
                    <DataTable
                        ref={refDt}
                        value={this.props.list}
                        paginator={paginator}
                        lazy={lazy}
                        paginatorLeft={paginatorLeft}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Total de registros: {totalRecords}"
                        rows={this.state.rows} rowsPerPageOptions={[10, 50, 100, 500]}
                        className='table table-striped my-4 w-100 p-datatable-sm'
                        totalRecords={this.props.list.length}
                        first={0}
                        onPage={this.onPage}
                        emptyMessage="Nenhum registro encontrado"
                        autoLayout={true}
                        csvSeparator=';'
                        scrollable={this.props.scrollable} 
                        selection={selectedItems}
                        onSelectionChange={this.onSelectionChange} 
                        selectionMode='checkbox'
                    >
                        {enableSelection && <Column selectionMode="multiple" style={{ width: '45px' }} />}
                        
                        {this.props.children}
                    </DataTable>
                }
            </>
        )

    }
}

TableNoLazy.defaultProps = {
    paginator: true,
    lazy: false,
    auto: true,
    showBtnExport: false,
    scrollable: false,
    rows: CONST_ROWS_PER_PAGE,
    enableSelection: false,
    batchActions: {},
    disableCondition: {},
    onPage: () => { },
};

export { TableNoLazy };