import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/contas/pagar' };
/////////////////////////////////////////////////////////////////////////////////

export function createContaPagar(payload) {
  return httpService.post(args, payload);
}

export function createContaPagarListAll(payload) {
  return httpService.post({ ...args, args: `/saveAll` }, payload);
}

export function updateContaPagar(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findContaPagar(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findPagamentos(id) {
  return httpService.get({ ...args, args: `/${id}/pagamentos` });
}

export function estornarPagamento(idConta, idPagamento) {
  return httpService.del({ ...args, args: `/${idConta}/estornar/${idPagamento}` });
}

export function estornarMultiplePagamento(payload) {
  return httpService.post({ ...args, args: `/estornarMultiplosPagamentos/` }, payload);
}

export function deleteContaPagar(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function deleteMultipleContaPagar(payload) {
  return httpService.post({ ...args, args: '/deleteMultiple' }, payload.ids);
}

export function attachFiles(contaId, payload) {
  return httpService.putAttachFiles({ ...args, args: `/attachFiles/${contaId}` }, payload);
}

export function deleteAttachFiles(contaId, filesIdList) {
  return httpService.putDeleteFiles({ ...args, args: `/deleteFiles/${contaId}` }, filesIdList);
}

export function getLancamentos(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `/getContasFilterPageable?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function getLancamentosPdf(idPropriedade, datas, where) {
  return httpService.getPdf({ ...args, args: `/relacaocontas/pdf?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function getLancamentosExcel(idPropriedade, datas, where) {
  return httpService.getFileExcel({ ...args, args: `/relacaocontas/excel?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function getTotaisContasPagar(idPropriedade, datas, where, filtroPgtos) {
  if (filtroPgtos) {
    return httpService.get({ ...args, args: `/totaisPgtos?propriedade=${idPropriedade}&datas=${datas}${where}` });
  }
  return httpService.get({ ...args, args: `/totais?propriedade=${idPropriedade}&&datas=${datas}${where}` });
}

export function getTotaisParaHoje(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `/find/today?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function filterPagamentos(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `/filterPagamentos?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function getPagamentosPdf(idPropriedade, datas, where) {
  return httpService.getPdf({ ...args, args: `/relacaoPagamentos/pdf?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function getPagamentosExcel(idPropriedade, datas, where) {
  return httpService.getFileExcel({ ...args, args: `/relacaoPagamentos/excel?propriedade=${idPropriedade}&datas=${datas}${where}` });
}