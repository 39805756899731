import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_CONTA_PAGAR_RECORRENTE_SUCCESS,
    TABLE_CONTA_PAGAR_RECORRENTE_PENDING,
    TABLE_CONTA_PAGAR_RECORRENTE_SUCCESS,
    TABLE_CONTA_PAGAR_RECORRENTE_FAILURE,
    LIST_CONTA_PAGAR_RECORRENTE_PENDING,
    LIST_CONTA_PAGAR_RECORRENTE_SUCCESS,
    LIST_CONTA_PAGAR_RECORRENTE_FAILURE,
    FIND_CONTA_PAGAR_RECORRENTE_PENDING,
    FIND_CONTA_PAGAR_RECORRENTE_SUCCESS,
    FIND_CONTA_PAGAR_RECORRENTE_FAILURE,
    DELETE_CONTA_PAGAR_RECORRENTE_PENDING,
    DELETE_CONTA_PAGAR_RECORRENTE_SUCCESS,
    DELETE_CONTA_PAGAR_RECORRENTE_FAILURE,
    CREATE_CONTA_PAGAR_RECORRENTE_PENDING,
    CREATE_CONTA_PAGAR_RECORRENTE_SUCCESS,
    CREATE_CONTA_PAGAR_RECORRENTE_FAILURE,
    ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_PENDING,
    ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_SUCCESS,
    ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_FAILURE,
    GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_PENDING,
    GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_SUCCESS,
    GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_FAILURE,
    NEW_FILTER_CONTA_PAGAR_RECORRENTE_PEDING,
    NEW_FILTER_CONTA_PAGAR_RECORRENTE_SUCCESS,
    NEW_FILTER_CONTA_PAGAR_RECORRENTE_FAILURE,
    NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_PENDING,
    NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_SUCCESS,
    NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_FAILURE,
} from './actionTypes';

const initialState = {
    list: null,
    loading: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}

function contaPagarRecorrenteReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_CONTA_PAGAR_RECORRENTE_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
            }
        case LIST_CONTA_PAGAR_RECORRENTE_PENDING:
        case FIND_CONTA_PAGAR_RECORRENTE_PENDING:
        case DELETE_CONTA_PAGAR_RECORRENTE_PENDING:
        case CREATE_CONTA_PAGAR_RECORRENTE_PENDING:
        case TABLE_CONTA_PAGAR_RECORRENTE_PENDING:
        case ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_PENDING:
        case GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_PENDING:
        case NEW_FILTER_CONTA_PAGAR_RECORRENTE_PEDING:
        case NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_CONTA_PAGAR_RECORRENTE_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_CONTA_PAGAR_RECORRENTE_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_CONTA_PAGAR_RECORRENTE_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_CONTA_PAGAR_RECORRENTE_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_CONTA_PAGAR_RECORRENTE_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_CONTA_PAGAR_RECORRENTE_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_CONTA_PAGAR_RECORRENTE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_CONTA_PAGAR_RECORRENTE_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_CONTA_PAGAR_RECORRENTE_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_CONTA_PAGAR_RECORRENTE_SUCCESS:
        case CREATE_CONTA_PAGAR_RECORRENTE_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro,
            }
        case DELETE_CONTA_PAGAR_RECORRENTE_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_FAILURE:
        case ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_SUCCESS:
        case GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_FAILURE:
        case GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { contaPagarRecorrenteReducer };