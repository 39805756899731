import { takeLatest, put, call } from 'redux-saga/effects';

import { baixarMultiplosPagamento } from '../service';
import swal from 'sweetalert';
import {
  BAIXAR_CONTA_PAGAMENTO_EM_LOTE,
  BAIXAR_CONTA_PAGAMENTO_EM_LOTE_PENDING,
  BAIXAR_CONTA_PAGAMENTO_EM_LOTE_SUCCESS,
  BAIXAR_CONTA_PAGAMENTO_EM_LOTE_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaBaixarPagamentoEmLote(action) {
  yield put({ type: BAIXAR_CONTA_PAGAMENTO_EM_LOTE_PENDING });

  try {
    let urlTipoConta = '';
    if (action.urlTipoConta === 'pagar') {
      urlTipoConta = '/contas/pagar';
    } else {
      urlTipoConta = '/contas/receber';
    }

    let registro = {
      ids: action.ids,
      tipoDataBaixa: action.data.tipoDataBaixa,
      dataBaixa: action.data.dataBaixa,
      identificador: action.data.identificador,
    }

    if (action.data.tipoPagamento === 'DINHEIRO') {
      registro = {
        ...registro,
        tipoPagamento: "DINHEIRO",
        pagamentoDinheiro: {
          contaCaixa: {
            id: action.data.conta.id
          }
        }
      }
    }

    if (action.data.tipoPagamento === 'BOLETO_BANCARIO' || action.data.tipoPagamento === 'PIX' || action.data.tipoPagamento === 'TRANSF_BANCARIA'
    || action.data.tipoPagamento === 'DEBITO_EM_CONTA' || action.data.tipoPagamento === 'CARNE' || action.data.tipoPagamento === 'GUIA'
    || action.data.tipoPagamento === 'CREDITO_EM_CONTA') {
      registro = {
        ...registro,
        tipoPagamento: action.data.tipoPagamento,
        
        pagamentoBanco: {
          contaBanco: {
            id: action.data.conta.id
          }
        }
      }
    }

    if (action.data.tipoPagamento === 'CARTAO_CREDITO' || action.data.tipoPagamento === 'CARTAO_DEBITO' || action.data.tipoPagamento === 'CARTAO_VOUCHER') {
      registro = formatJsonRemoveEmptyOrNull(
        {
          ...registro,
          tipoPagamento: action.data.tipoPagamento,
          pagamentoBanco: {
            cartaoBandeira: action.data.cartaoBandeira,
            cartaoNsu: action.data.cartaoNsu,
            chequeEmitente: '',
            chequeNumeroCheque: '',
            chequeBanco: '',
            chequeNumeroAgencia: '',
            chequeDvAgencia: '',
            chequeContaCorrente: '',
            chequeDvContaCorrente: '',
            contaBanco: {
              id: action.data.conta.id
            }
          }
        }
      )
    }

    if (action.data.tipoPagamento === 'CHEQUE_A_VISTA' || action.data.tipoPagamento === 'CHEQUE_PRE_DATADO') {
      registro = formatJsonRemoveEmptyOrNull(
        {
          ...registro,
          tipoPagamento: action.data.tipoPagamento,
          pagamentoBanco: {
            cartaoBandeira: '',
            cartaoNsu: '',
            chequeEmitente: action.data.chequeEmitente,
            chequeNumeroCheque: action.data.chequeNro,
            chequeBanco: action.data.chequeBanco,
            chequeNumeroAgencia: action.data.chequeNroAg,
            chequeDvAgencia: action.data.chequeDvAg,
            chequeContaCorrente: action.data.chequeNroCc,
            chequeDvContaCorrente: action.data.chequeDvCc,
            contaBanco: {
              id: action.data.conta.id
            }
          }
        }
      )
    }


    yield yield call(baixarMultiplosPagamento, urlTipoConta, registro);

    yield put({ type: BAIXAR_CONTA_PAGAMENTO_EM_LOTE_SUCCESS });
    swal({
      title: 'Pagamentos realizados com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

  } catch (error) {
    yield put({ type: BAIXAR_CONTA_PAGAMENTO_EM_LOTE_FAILURE })

    AlertError('Falha ao realizar baixa das contas. Tente novamente.');
  }
}

export default function* watchBaixarPagamentoEmLote() {
  yield takeLatest(BAIXAR_CONTA_PAGAMENTO_EM_LOTE, sagaBaixarPagamentoEmLote)
}
