import { takeLatest, put, call } from 'redux-saga/effects';

import { findRomaneioColheita } from '../service';
import {
  FIND_ROMANEIO_COLHEITA,
  FIND_ROMANEIO_COLHEITA_PENDING,
  FIND_ROMANEIO_COLHEITA_SUCCESS,
  FIND_ROMANEIO_COLHEITA_FAILURE
} from '../actionTypes';

function* sagaFindRomaneioColheita(action) {
  yield put({ type: FIND_ROMANEIO_COLHEITA_PENDING, id: action.id })

  try {

    const registro = yield call(findRomaneioColheita, action.id);

    yield put({ type: FIND_ROMANEIO_COLHEITA_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_ROMANEIO_COLHEITA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindRomaneioColheita() {
  yield takeLatest(FIND_ROMANEIO_COLHEITA, sagaFindRomaneioColheita)
}
