import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllCentroCustoCadastro } from '../service';
import {
  LIST_CENTRO_CUSTO_CADASTRO,
  LIST_CENTRO_CUSTO_CADASTRO_PENDING,
  LIST_CENTRO_CUSTO_CADASTRO_SUCCESS,
  LIST_CENTRO_CUSTO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllCentroCustoCadastro(action) {
  yield put({ type: LIST_CENTRO_CUSTO_CADASTRO_PENDING })

  try {
    const list = yield call(findAllCentroCustoCadastro)
    
    yield put({ type: LIST_CENTRO_CUSTO_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_CENTRO_CUSTO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllCentroCustoCadastro() {
  yield takeLatest(LIST_CENTRO_CUSTO_CADASTRO, sagaListAllCentroCustoCadastro)
}