import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  baixaConta,
  BAIXAR_CONTA_PAGAMENTO_EM_LOTE_PENDING,
  NEW_BAIXAR_CONTA_EM_LOTE,
  NEW_BAIXAR_CONTA_EM_LOTE_SUCCESS,
} from '@handler';

function* sagaNewBaixarContaEmLote(action) {
  yield put({ type: BAIXAR_CONTA_PAGAMENTO_EM_LOTE_PENDING });

  yield put({
    type: NEW_BAIXAR_CONTA_EM_LOTE_SUCCESS, registro:
    {
      ...baixaConta.newRegistro,
      dataBaixa: moment().startOf('day').format('YYYY-MM-DD'),
      tipoDataBaixa: 'INFORMAR_DATA',
      tipoPagamento: 'DINHEIRO',
    }
  });
}

export default function* watchNewBaixarContaEmLote() {
  yield takeLatest(NEW_BAIXAR_CONTA_EM_LOTE, sagaNewBaixarContaEmLote)
}
