


import watchNewCentroCustoCadastro from './saga/new';
import watchFindCentroCustoCadastro from './saga/find';
import watchCreateCentroCustoCadastro from './saga/create';
import watchListAllCentroCustoCadastro from './saga/listAll';
import watchListCentroCustoCadastro from './saga/listTable';
import watchDeleteCentroCustoCadastro from './saga/delete';
import watchCancelCentroCustoCadastroRapido from './saga/cancel';

export const centroCustoCadastroSaga = [
    watchNewCentroCustoCadastro(),
    watchFindCentroCustoCadastro(),
    watchCreateCentroCustoCadastro(),
    watchListAllCentroCustoCadastro(),
    watchListCentroCustoCadastro(),
    watchDeleteCentroCustoCadastro(),
    watchCancelCentroCustoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
