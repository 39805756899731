import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_ROMANEIO_COLHEITA_SUCCESS,
    TABLE_ROMANEIO_COLHEITA_PENDING,
    TABLE_ROMANEIO_COLHEITA_SUCCESS,
    TABLE_ROMANEIO_COLHEITA_FAILURE,
    LIST_ROMANEIO_COLHEITA_PENDING,
    LIST_ROMANEIO_COLHEITA_SUCCESS,
    LIST_ROMANEIO_COLHEITA_FAILURE,
    FIND_ROMANEIO_COLHEITA_PENDING,
    FIND_ROMANEIO_COLHEITA_SUCCESS,
    FIND_ROMANEIO_COLHEITA_FAILURE,
    DELETE_ROMANEIO_COLHEITA_PENDING,
    DELETE_ROMANEIO_COLHEITA_SUCCESS,
    DELETE_ROMANEIO_COLHEITA_FAILURE,
    CREATE_ROMANEIO_COLHEITA_PENDING,
    CREATE_ROMANEIO_COLHEITA_SUCCESS,
    CREATE_ROMANEIO_COLHEITA_FAILURE,
    NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_PENDING,
    NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_SUCCESS,
    NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_FAILURE,
    NEW_FILTER_ROMANEIO_COLHEITA_PEDING,
    NEW_FILTER_ROMANEIO_COLHEITA_SUCCESS,
    NEW_FILTER_ROMANEIO_COLHEITA_FAILURE,
    GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_SUCCESS,
    GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_FAILURE,
    GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_PENDING,
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    loadingTotais: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}


function romaneioColheitaReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_ROMANEIO_COLHEITA_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_ROMANEIO_COLHEITA_PENDING:
        case FIND_ROMANEIO_COLHEITA_PENDING:
        case DELETE_ROMANEIO_COLHEITA_PENDING:
        case CREATE_ROMANEIO_COLHEITA_PENDING:
        case TABLE_ROMANEIO_COLHEITA_PENDING:
        case NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_PENDING:
        case NEW_FILTER_ROMANEIO_COLHEITA_PEDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_ROMANEIO_COLHEITA_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_ROMANEIO_COLHEITA_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_ROMANEIO_COLHEITA_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_ROMANEIO_COLHEITA_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_ROMANEIO_COLHEITA_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_ROMANEIO_COLHEITA_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_ROMANEIO_COLHEITA_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_ROMANEIO_COLHEITA_SUCCESS:
        case CREATE_ROMANEIO_COLHEITA_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_ROMANEIO_COLHEITA_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_ROMANEIO_COLHEITA_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_ROMANEIO_COLHEITA_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_PENDING:
            return {
                ...state,
                loading: true
            }
        case GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_FAILURE:
        case GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_SUCCESS:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { romaneioColheitaReducer };