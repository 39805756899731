import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createPlanoContaCadastro, updatePlanoContaCadastro } from '../service';
import {
  CREATE_PLANO_CONTA_CADASTRO,
  CREATE_PLANO_CONTA_CADASTRO_PENDING,
  CREATE_PLANO_CONTA_CADASTRO_SUCCESS,
  CREATE_PLANO_CONTA_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatStringFirstWordUpperCase } from '@components/common/format';

function* sagaCreatePlanoContaCadastro(action) {
  yield put({ type: CREATE_PLANO_CONTA_CADASTRO_PENDING })

  try {
    if (action.data.tipoConta === 'S') {
      action.data.descricao = action.data.descricao.toUpperCase();
      // values.data.disponivelDre = true;
    } else {
      action.data.descricao = formatStringFirstWordUpperCase(action.data.descricao);
    }
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createPlanoContaCadastro, action.data);
    } else {
      yield call(updatePlanoContaCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_PLANO_CONTA_CADASTRO_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      history.push('/financeiro/planoContaCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_PLANO_CONTA_CADASTRO_FAILURE, registro: action.data })

    if (error.response !== undefined && error.response !== null && 
      error.response.status !== undefined && error.response.status !== null && 
      error.response.status === 422) {
      AlertWarning(error.response.data.text);
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreatePlanoContaCadastro() {
  yield takeLatest(CREATE_PLANO_CONTA_CADASTRO, sagaCreatePlanoContaCadastro)
}
