
export const NEW_COLHEITA = 'NEW_COLHEITA'
export const NEW_COLHEITA_SUCCESS = 'NEW_COLHEITA_SUCCESS'

export const CREATE_COLHEITA = 'CREATE_COLHEITA'
export const CREATE_COLHEITA_PENDING = 'CREATE_COLHEITA_PENDING'
export const CREATE_COLHEITA_SUCCESS = 'CREATE_COLHEITA_SUCCESS'
export const CREATE_COLHEITA_FAILURE = 'CREATE_COLHEITA_FAILURE'

export const TABLE_COLHEITA = 'TABLE_COLHEITA'
export const TABLE_COLHEITA_PENDING = 'TABLE_COLHEITA_PENDING'
export const TABLE_COLHEITA_SUCCESS = 'TABLE_COLHEITA_SUCCESS'
export const TABLE_COLHEITA_FAILURE = 'TABLE_COLHEITA_FAILURE'

export const LIST_COLHEITA = 'LIST_COLHEITA'
export const LIST_COLHEITA_PENDING = 'LIST_COLHEITA_PENDING'
export const LIST_COLHEITA_SUCCESS = 'LIST_COLHEITA_SUCCESS'
export const LIST_COLHEITA_FAILURE = 'LIST_COLHEITA_FAILURE'

export const FIND_COLHEITA = 'FIND_COLHEITA'
export const FIND_COLHEITA_PENDING = 'FIND_COLHEITA_PENDING'
export const FIND_COLHEITA_SUCCESS = 'FIND_COLHEITA_SUCCESS'
export const FIND_COLHEITA_FAILURE = 'FIND_COLHEITA_FAILURE'

export const DELETE_COLHEITA = 'DELETE_COLHEITA'
export const DELETE_COLHEITA_PENDING = 'DELETE_COLHEITA_PENDING'
export const DELETE_COLHEITA_SUCCESS = 'DELETE_COLHEITA_SUCCESS'
export const DELETE_COLHEITA_FAILURE = 'DELETE_COLHEITA_FAILURE'

export const NEW_FILTER_COLHEITA = 'NEW_FILTER_COLHEITA'
export const NEW_FILTER_COLHEITA_PEDING = 'NEW_FILTER_COLHEITA_PEDING'
export const NEW_FILTER_COLHEITA_SUCCESS = 'NEW_FILTER_COLHEITA_SUCCESS'
export const NEW_FILTER_COLHEITA_FAILURE = 'NEW_FILTER_COLHEITA_FAILURE'

export const NEW_FILTER_NEXT_PAGE_COLHEITA = 'NEW_FILTER_NEXT_PAGE_COLHEITA'
export const NEW_FILTER_NEXT_PAGE_COLHEITA_PENDING = 'NEW_FILTER_NEXT_PAGE_COLHEITA_PENDING'
export const NEW_FILTER_NEXT_PAGE_COLHEITA_SUCCESS = 'NEW_FILTER_NEXT_PAGE_COLHEITA_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_COLHEITA_FAILURE = 'NEW_FILTER_NEXT_PAGE_COLHEITA_FAILURE'

export const GET_TOTAIS_COLHEITA_PENDING = 'GET_TOTAIS_COLHEITA_PENDING'
export const GET_TOTAIS_COLHEITA_SUCCESS = 'GET_TOTAIS_COLHEITA_SUCCESS'
export const GET_TOTAIS_COLHEITA_FAILURE = 'GET_TOTAIS_COLHEITA_FAILURE'

export const GET_LOADING_COLHEITA_SUCCESS = 'GET_LOADING_COLHEITA_SUCCESS'
export const GET_LOADING_COLHEITA_PENDING = 'GET_LOADING_COLHEITA_PENDING'

export const RELATORIO_COLHEITA = 'RELATORIO_COLHEITA'

export const GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_PENDING = 'GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_PENDING'
export const GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_SUCCESS = 'GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_SUCCESS'
export const GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_FAILURE = 'GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_FAILURE'

export const GET_GRAFICO_TOTAL_COLHIDO_PENDING = 'GET_GRAFICO_TOTAL_COLHIDO_PENDING'
export const GET_GRAFICO_TOTAL_COLHIDO_SUCCESS = 'GET_GRAFICO_TOTAL_COLHIDO_SUCCESS'
export const GET_GRAFICO_TOTAL_COLHIDO_FAILURE = 'GET_GRAFICO_TOTAL_COLHIDO_FAILURE'

export const GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_PENDING = "GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_PENDING";
export const GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_SUCCESS = "GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_SUCCESS";
export const GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_FAILURE = "GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_FAILURE";
