
export const NEW_CONTA_PAGAR_RECORRENTE = 'NEW_CONTA_PAGAR_RECORRENTE'
export const NEW_CONTA_PAGAR_RECORRENTE_PENDING = 'NEW_CONTA_PAGAR_RECORRENTE_PENDING'
export const NEW_CONTA_PAGAR_RECORRENTE_SUCCESS = 'NEW_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const NEW_CONTA_PAGAR_RECORRENTE_FAILURE = 'NEW_CONTA_PAGAR_RECORRENTE_FAILURE'

export const NEW_FILTER_CONTA_PAGAR_RECORRENTE = 'NEW_FILTER_CONTA_PAGAR_RECORRENTE'
export const NEW_FILTER_CONTA_PAGAR_RECORRENTE_PEDING = 'NEW_FILTER_CONTA_PAGAR_RECORRENTE_PEDING'
export const NEW_FILTER_CONTA_PAGAR_RECORRENTE_SUCCESS = 'NEW_FILTER_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const NEW_FILTER_CONTA_PAGAR_RECORRENTE_FAILURE = 'NEW_FILTER_CONTA_PAGAR_RECORRENTE_FAILURE'

export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE'
export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_PENDING = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_PENDING'
export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_SUCCESS = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_FAILURE = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_RECORRENTE_FAILURE'

export const CREATE_CONTA_PAGAR_RECORRENTE = 'CREATE_CONTA_PAGAR_RECORRENTE'
export const CREATE_CONTA_PAGAR_RECORRENTE_PENDING = 'CREATE_CONTA_PAGAR_RECORRENTE_PENDING'
export const CREATE_CONTA_PAGAR_RECORRENTE_SUCCESS = 'CREATE_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const CREATE_CONTA_PAGAR_RECORRENTE_FAILURE = 'CREATE_CONTA_PAGAR_RECORRENTE_FAILURE'

export const TABLE_CONTA_PAGAR_RECORRENTE = 'TABLE_CONTA_PAGAR_RECORRENTE'
export const TABLE_CONTA_PAGAR_RECORRENTE_PENDING = 'TABLE_CONTA_PAGAR_RECORRENTE_PENDING'
export const TABLE_CONTA_PAGAR_RECORRENTE_SUCCESS = 'TABLE_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const TABLE_CONTA_PAGAR_RECORRENTE_FAILURE = 'TABLE_CONTA_PAGAR_RECORRENTE_FAILURE'

export const LIST_CONTA_PAGAR_RECORRENTE = 'LIST_CONTA_PAGAR_RECORRENTE'
export const LIST_CONTA_PAGAR_RECORRENTE_PENDING = 'LIST_CONTA_PAGAR_RECORRENTE_PENDING'
export const LIST_CONTA_PAGAR_RECORRENTE_SUCCESS = 'LIST_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const LIST_CONTA_PAGAR_RECORRENTE_FAILURE = 'LIST_CONTA_PAGAR_RECORRENTE_FAILURE'

export const FIND_CONTA_PAGAR_RECORRENTE = 'FIND_CONTA_PAGAR_RECORRENTE'
export const FIND_CONTA_PAGAR_RECORRENTE_PENDING = 'FIND_CONTA_PAGAR_RECORRENTE_PENDING'
export const FIND_CONTA_PAGAR_RECORRENTE_SUCCESS = 'FIND_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const FIND_CONTA_PAGAR_RECORRENTE_FAILURE = 'FIND_CONTA_PAGAR_RECORRENTE_FAILURE'

export const DELETE_CONTA_PAGAR_RECORRENTE = 'DELETE_CONTA_PAGAR_RECORRENTE'
export const DELETE_CONTA_PAGAR_RECORRENTE_PENDING = 'DELETE_CONTA_PAGAR_RECORRENTE_PENDING'
export const DELETE_CONTA_PAGAR_RECORRENTE_SUCCESS = 'DELETE_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const DELETE_CONTA_PAGAR_RECORRENTE_FAILURE = 'DELETE_CONTA_PAGAR_RECORRENTE_FAILURE'

export const ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE = 'ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE'
export const ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_PENDING = 'ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_PENDING'
export const ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_SUCCESS = 'ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_FAILURE = 'ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_FAILURE'

export const GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE = 'GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE'
export const GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_PENDING = 'GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_PENDING'
export const GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_SUCCESS = 'GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_SUCCESS'
export const GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_FAILURE = 'GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_FAILURE'
