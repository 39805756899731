import * as Yup from 'yup';

const newRegistro = {
    tipoOcorrencia: 'UNICA',
    data: '',
    dataEmissao: '',
    competencia: '',
    numeroDoc: '',
    valor: 0,
    tipoEntidade: 'PESSOA',
    pessoa: null,
    motorista: null,
    funcionario: null,
    nomeEntidadeOutros: '',
    contaGerencial: null,
    centroCusto: null,
    identificador: null,
    historico: '',
    automatico: false,
    obs: '',
    forJson: 'contareceber',
    parcelas: [],
    dataPrimeiraParcela: '',
    numeroParcelas: null,
    contaPaga: false,
    tipoPagamento: null,
    contaCaixa: null,
    contaBanco: null,
};

const validation = Yup.object().shape({
    tipoOcorrencia: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    data: Yup.date().when('tipoOcorrencia', {
        is: (tipoOcorrencia) => tipoOcorrencia === 'UNICA',
        then: Yup.date()
            .required('Este campo é obrigatório')
    }).nullable(),
    dataEmissao: Yup.date()
        .required('Este campo é obrigatório'),
    competencia: Yup.date().when('tipoOcorrencia', {
        is: (tipoOcorrencia) => tipoOcorrencia === 'UNICA',
        then: Yup.date()
            .required('Este campo é obrigatório')
    }).nullable(),
    tipoEntidade: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    contaGerencial: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    historico: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    valor: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    numeroDoc: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    obs: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    cartaoNsu: Yup.string()
        .max(20, 'Campo deve ter menos do que 20 caracteres'),
    chequeEmitente: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    chequeNro: Yup.string()
        .max(15, 'Campo deve ter menos do que 15 caracteres'),
    chequeBanco: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    chequeNroAg: Yup.string()
        .max(4, 'Campo deve ter menos do que 4 caracteres'),
    chequeDvAg: Yup.string()
        .max(2, 'Campo deve ter menos do que 2 caracteres'),
    chequeNroCc: Yup.string()
        .max(20, 'Campo deve ter menos do que 20 caracteres'),
    chequeDvCc: Yup.string()
        .max(2, 'Campo deve ter menos do que 2 caracteres'),
    parcelas: Yup.array().of(
        Yup.object().shape({
            competencia: Yup.date().required('Este campo é obrigatório'),
            cartaoNsu: Yup.string().max(20, 'Campo deve ter menos do que 20 caracteres'),
            chequeEmitente: Yup.string().max(60, 'Campo deve ter menos do que 60 caracteres'),
            chequeNro: Yup.string().max(15, 'Campo deve ter menos do que 15 caracteres'),
            chequeBanco: Yup.string().max(60, 'Campo deve ter menos do que 60 caracteres'),
            chequeNroAg: Yup.string().max(4, 'Campo deve ter menos do que 4 caracteres'),
            chequeDvAg: Yup.string().max(2, 'Campo deve ter menos do que 2 caracteres'),
            chequeNroCc: Yup.string().max(20, 'Campo deve ter menos do que 20 caracteres'),
            chequeDvCc: Yup.string().max(2, 'Campo deve ter menos do que 2 caracteres'),
        })
    ),
});

export const contaReceber = {
    newRegistro,
    validation
}