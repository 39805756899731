import { takeLatest, put, call } from 'redux-saga/effects';

import { findCentroCustoCadastro } from '../service';
import {
  FIND_CENTRO_CUSTO_CADASTRO,
  FIND_CENTRO_CUSTO_CADASTRO_PENDING,
  FIND_CENTRO_CUSTO_CADASTRO_SUCCESS,
  FIND_CENTRO_CUSTO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindCentroCustoCadastro(action) {
  yield put({ type: FIND_CENTRO_CUSTO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findCentroCustoCadastro, action.id);

    yield put({ type: FIND_CENTRO_CUSTO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CENTRO_CUSTO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindCentroCustoCadastro() {
  yield takeLatest(FIND_CENTRO_CUSTO_CADASTRO, sagaFindCentroCustoCadastro)
}
