import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';
import moment from 'moment';

import { createNFeEmissao, findNFeEmissao, updateNFeEmissao } from '../service';
import {
  CREATE_NFE_EMISSAO,
  CREATE_NFE_EMISSAO_PENDING,
  CREATE_NFE_EMISSAO_SUCCESS,
  CREATE_NFE_EMISSAO_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateNFeEmissao(action) {
  yield put({ type: CREATE_NFE_EMISSAO_PENDING })

  try {
    var nfeSalva = null;
    var nfeRetorno = null;
    if (action.data.id === undefined || action.data.id === null) {
      nfeSalva = yield call(createNFeEmissao, {
        ...action.data,
        data: moment().format('YYYY-MM-DD HH:mm:ss'),
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      });
      nfeRetorno = yield call(findNFeEmissao, nfeSalva.data.id);
    } else {
      nfeSalva = yield call(updateNFeEmissao,
        {
          ...action.data,
          data: moment().format('YYYY-MM-DD HH:mm:ss'),
          id: action.data.id
        }
      );
      nfeRetorno = yield call(findNFeEmissao, nfeSalva.id);
    }
    
    yield put({ type: CREATE_NFE_EMISSAO_SUCCESS, registro: nfeRetorno })

    AlertSuccess('NFe salva com sucesso!');

    history.push('/fiscal/nfe/emissao/view/' + nfeRetorno.id);
  } catch (error) {
    yield put({ type: CREATE_NFE_EMISSAO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar a NFe! Tente novamente...');
  }
}

export default function* watchCreateNFeEmissao() {
  yield takeLatest(CREATE_NFE_EMISSAO, sagaCreateNFeEmissao)
}
