
export const NEW_ROMANEIO_COLHEITA = 'NEW_ROMANEIO_COLHEITA'
export const NEW_ROMANEIO_COLHEITA_SUCCESS = 'NEW_ROMANEIO_COLHEITA_SUCCESS'

export const CREATE_ROMANEIO_COLHEITA = 'CREATE_ROMANEIO_COLHEITA'
export const CREATE_ROMANEIO_COLHEITA_PENDING = 'CREATE_ROMANEIO_COLHEITA_PENDING'
export const CREATE_ROMANEIO_COLHEITA_SUCCESS = 'CREATE_ROMANEIO_COLHEITA_SUCCESS'
export const CREATE_ROMANEIO_COLHEITA_FAILURE = 'CREATE_ROMANEIO_COLHEITA_FAILURE'

export const TABLE_ROMANEIO_COLHEITA = 'TABLE_ROMANEIO_COLHEITA'
export const TABLE_ROMANEIO_COLHEITA_PENDING = 'TABLE_ROMANEIO_COLHEITA_PENDING'
export const TABLE_ROMANEIO_COLHEITA_SUCCESS = 'TABLE_ROMANEIO_COLHEITA_SUCCESS'
export const TABLE_ROMANEIO_COLHEITA_FAILURE = 'TABLE_ROMANEIO_COLHEITA_FAILURE'

export const LIST_ROMANEIO_COLHEITA = 'LIST_ROMANEIO_COLHEITA'
export const LIST_ROMANEIO_COLHEITA_PENDING = 'LIST_ROMANEIO_COLHEITA_PENDING'
export const LIST_ROMANEIO_COLHEITA_SUCCESS = 'LIST_ROMANEIO_COLHEITA_SUCCESS'
export const LIST_ROMANEIO_COLHEITA_FAILURE = 'LIST_ROMANEIO_COLHEITA_FAILURE'

export const FIND_ROMANEIO_COLHEITA = 'FIND_ROMANEIO_COLHEITA'
export const FIND_ROMANEIO_COLHEITA_PENDING = 'FIND_ROMANEIO_COLHEITA_PENDING'
export const FIND_ROMANEIO_COLHEITA_SUCCESS = 'FIND_ROMANEIO_COLHEITA_SUCCESS'
export const FIND_ROMANEIO_COLHEITA_FAILURE = 'FIND_ROMANEIO_COLHEITA_FAILURE'

export const DELETE_ROMANEIO_COLHEITA = 'DELETE_ROMANEIO_COLHEITA'
export const DELETE_ROMANEIO_COLHEITA_PENDING = 'DELETE_ROMANEIO_COLHEITA_PENDING'
export const DELETE_ROMANEIO_COLHEITA_SUCCESS = 'DELETE_ROMANEIO_COLHEITA_SUCCESS'
export const DELETE_ROMANEIO_COLHEITA_FAILURE = 'DELETE_ROMANEIO_COLHEITA_FAILURE'

export const NEW_FILTER_ROMANEIO_COLHEITA = 'NEW_FILTER_ROMANEIO_COLHEITA'
export const NEW_FILTER_ROMANEIO_COLHEITA_PEDING = 'NEW_FILTER_ROMANEIO_COLHEITA_PEDING'
export const NEW_FILTER_ROMANEIO_COLHEITA_SUCCESS = 'NEW_FILTER_ROMANEIO_COLHEITA_SUCCESS'
export const NEW_FILTER_ROMANEIO_COLHEITA_FAILURE = 'NEW_FILTER_ROMANEIO_COLHEITA_FAILURE'

export const NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA = 'NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA'
export const NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_PENDING = 'NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_PENDING'
export const NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_SUCCESS = 'NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_FAILURE = 'NEW_FILTER_NEXT_PAGE_ROMANEIO_COLHEITA_FAILURE'

export const GET_RELATORIO_ROMANEIO_COLHEITA_ITEM = 'GET_RELATORIO_ROMANEIO_COLHEITA_ITEM'
export const GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_SUCCESS = 'GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_SUCCESS'
export const GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_FAILURE = 'GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_FAILURE'
export const GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_PENDING = 'GET_RELATORIO_ROMANEIO_COLHEITA_ITEM_PENDING'