import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/romaneioColheita' };
/////////////////////////////////////////////////////////////////////////////////

export function createRomaneioColheita(payload) {
  return httpService.post(args, payload);
}

export function updateRomaneioColheita(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listRomaneioColheita(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function listRomaneioColheitaTable(param) {
  return httpService.get({ ...args, args: param });
}

export function findRomaneioColheita(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteRomaneioColheita(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getRelatorioRomaneioColheitaItem(idRomaneio) {
  return httpService.getPdf({ ...args, args: `/relatorioRomaneioById/pdf/${idRomaneio}` });
}
