import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createVendaProducaoAgricola, updateVendaProducaoAgricola } from '../service';
import {
  CREATE_VENDA_PRODUCAO_AGRICOLA,
  CREATE_VENDA_PRODUCAO_AGRICOLA_PENDING,
  CREATE_VENDA_PRODUCAO_AGRICOLA_SUCCESS,
  CREATE_VENDA_PRODUCAO_AGRICOLA_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateVendaProducaoAgricola(action) {
  yield put({ type: CREATE_VENDA_PRODUCAO_AGRICOLA_PENDING })

  var processar = true;
  // if (action.data.id !== undefined && action.data.id !== null) {
  //   processar = yield call(entradaPodeSerEditadaExcluida, action.data.id);
  // }

  if (processar) {
    try {
      if (!action.data.lancaProduto) {
        action.data.itens = [];
      }
      
      if (!action.data.vendaDup.geraDuplicata) {
        action.data.vendaDup.tipoDuplicata = null;
        action.data.vendaDup.contaGerencial = null;
        action.data.vendaDup.centroCusto = null;
        action.data.vendaDup.historico = '';
        action.data.vendaDup.tipoPagamento = null;
        action.data.vendaDup.contaCaixa = null;
        action.data.vendaDup.contaBanco = null;
        action.data.vendaDup.numeroParcelas = null;
        action.data.parcelas = [];
      } else if (action.data.vendaDup.geraDuplicata) {
        if (action.data.vendaDup.tipoDuplicata === 'A_VISTA') {
          action.data.vendaDup.numeroParcelas = null;
          action.data.parcelas = [];
          if (action.data.vendaDup.movimentaFinanceiro === false) {
            action.data.vendaDup.contaGerencial = null;
            action.data.vendaDup.centroCusto = null;
            action.data.vendaDup.historico = '';
            action.data.vendaDup.tipoPagamento = null;
            action.data.vendaDup.contaCaixa = null;
            action.data.vendaDup.contaBanco = null;
          }
        } else if (action.data.vendaDup.tipoDuplicata === 'PARCELADA' && action.data.vendaDup.movimentaFinanceiro === false) {
          for (var i = 0; i < action.data.parcelas.length; i++) {
            action.data.parcelas[i].contaGerencial = null;
            action.data.parcelas[i].centroCusto = null;
            action.data.parcelas[i].historico = '';
            action.data.parcelas[i].parcelaPaga = false;
            action.data.parcelas[i].tipoPagamento = null;
            action.data.parcelas[i].contaCaixa = null;
            action.data.parcelas[i].contaBanco = null;
          }
        }
      }

      if (action.data.talhao !== undefined && action.data.talhao != null){
        if (action.data.talhao.talhao !== undefined && action.data.talhao.talhao !== null) {
          action.data.talhao = action.data.talhao.talhao;
        }
      }

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createVendaProducaoAgricola, formatJsonRemoveEmptyOrNull({
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
          safra: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id
          } 
        }));
      } else {
        let lancamentosContaReceber = [];
        action.data.lancamentosContaReceber.forEach(element => {
          lancamentosContaReceber = [
            ...lancamentosContaReceber,
            {
              "forJson": "contareceber",
              "id": element.id,
              "valor": element.valor,
              "historico": element.historico,
              "propriedade": { "id": element.propriedade.id },
              "contaGerencial": {
                "id": element.contaGerencial.id,
                "tipoConta": "A"
              }
            }
          ]
        });
        
        yield call(updateVendaProducaoAgricola,
          {
            ...action.data,
            lancamentosContaReceber: lancamentosContaReceber,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_VENDA_PRODUCAO_AGRICOLA_SUCCESS, registro: action.data })

      history.push('/agricultura/producao/venda');

      AlertSuccess('Venda registrada com sucesso!');
    } catch (error) {
      yield put({ type: CREATE_VENDA_PRODUCAO_AGRICOLA_FAILURE, registro: action.data })

      AlertError('Falha ao salvar a venda! Tente novamente...');
    }
  } else {
    yield put({ type: CREATE_VENDA_PRODUCAO_AGRICOLA_FAILURE, registro: action.data })

    AlertWarning('Venda não pode ser editada, pois já houve emissão de NFe');
  }
}

export default function* watchCreateVendaProducaoAgricola() {
  yield takeLatest(CREATE_VENDA_PRODUCAO_AGRICOLA, sagaCreateVendaProducaoAgricola)
}
