import { takeLatest, put, call, all } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_ROMANEIO_COLHEITA,
  NEW_FILTER_ROMANEIO_COLHEITA_PEDING,
  NEW_FILTER_ROMANEIO_COLHEITA_SUCCESS,
  NEW_FILTER_ROMANEIO_COLHEITA_FAILURE,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { listRomaneioColheita, } from '../service';
import { CONST_FILTER_ROMANEIO_COLHEITA, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterPlanejamento(action) {
  try {
    yield put({ type: NEW_FILTER_ROMANEIO_COLHEITA_PEDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idSafraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.id ?? -1;

    if (idSafraAtiva !== -1) {
      let datas = '';
      let dataInicial = '';
      let dataFinal = '';
      var date = new Date();
      let where = '';
      let newFilter = {
        talhaoSelecionado: null,
        localEstoque: null,
        funcionario: null,
        identificador: null,
        motorista: null,
        dataInicial: '',
        dataFinal: '',
        rows: CONST_ROWS_PER_PAGE,
        page: 0,
        where: ''
      };

      let filterSaved = sessionStorage.getItem(CONST_FILTER_ROMANEIO_COLHEITA) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_ROMANEIO_COLHEITA)) : null;

      if (filterSaved === null || filterSaved === undefined) {
        // seta data da safra ou do mes na primeira vez que entra na pagina
        dataInicial = formatDateYYYYMMDD(JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.dataInicial ?? new Date(date.getFullYear(), date.getMonth(), 1)),
          dataFinal = formatDateYYYYMMDD(JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.dataFinal ?? new Date(date.getFullYear(), date.getMonth() + 1, 0)),

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

        newFilter = {
          ...newFilter,
          dataInicial,
          dataFinal,
        };
      } else {
        // se seta filtro manual
        if (action.newFilter) {
          // seta filtro indicado
          // typePeriodo = 0 -> periodo manual
          // typePeriodo = 1 -> hoje
          // typePeriodo = 2 -> esta semana
          // typePeriodo = 3 -> mes passado
          // typePeriodo = 4 -> este mes
          // typePeriodo = 5 -> proximo mes
          // typePeriodo = 6 -> este ano
          if (action.typePeriodo === undefined || action.typePeriodo === 0) {
            dataInicial = action.args.dataInicial;
            dataFinal = action.args.dataFinal;

            datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
          } else if (action.typePeriodo === 1) {
            dataInicial = formatDateYYYYMMDD(new Date(date));
            dataFinal = formatDateYYYYMMDD(new Date(date));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          }
          else if (action.typePeriodo === 2) {
            const primeiro = date.getDate() - date.getDay();

            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 3) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 4) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 5) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 6) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          }

          if (action.args.talhaoSelecionado !== null && action.args.talhaoSelecionado !== undefined) {
            where = where + '&talhao=' + action.args.talhaoSelecionado.talhao.id;
          }
          if (action.args.localEstoque !== null && action.args.localEstoque !== undefined) {
            where = where + '&localEstoque=' + action.args.localEstoque.id;
          }
          if (action.args.identificador !== null && action.args.identificador !== undefined) {
            where = where + '&identificador=' + action.args.identificador.id;
          }
          if (action.args.funcionario !== null && action.args.funcionario !== undefined) {
            where = where + '&funcionario=' + action.args.funcionario.id;
          }
          if (action.args.motorista !== null && action.args.motorista !== undefined) {
            where = where + '&motorista=' + action.args.motorista.id;
          }

          newFilter = {
            ...newFilter,
            ...action.args,
            dataInicial,
            dataFinal,
            where: where
          };
        } else {
          // busca filtro salvo
          datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

          if (filterSaved.talhaoSelecionado !== null && filterSaved.talhaoSelecionado !== undefined) {
            where = where + '&talhao=' + filterSaved.talhaoSelecionado.talhao.id;
          }
          if (filterSaved.localEstoque !== null && filterSaved.localEstoque !== undefined) {
            where = where + '&localEstoque=' + filterSaved.localEstoque.id;
          }
          if (filterSaved.identificador !== null && filterSaved.identificador !== undefined) {
            where = where + '&identificador=' + filterSaved.identificador.id;
          }
          if (filterSaved.funcionario !== null && filterSaved.funcionario !== undefined) {
            where = where + '&funcionario=' + filterSaved.funcionario.id;
          }
          if (filterSaved.motorista !== null && filterSaved.motorista !== undefined) {
            where = where + '&motorista=' + filterSaved.motorista.id;
          }

          newFilter = {
            ...filterSaved,
            rows: CONST_ROWS_PER_PAGE,
            page: 0,
            where: where
          };
        }
      }

      // Fazendo as chamadas em paralelo!
      const [list] = yield all([
        call(listRomaneioColheita, idFazenda, idSafraAtiva, `${formatDateDDMMYYYY(dataInicial)}_${formatDateDDMMYYYY(dataFinal)}`, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`),
      ]);

      sessionStorage.setItem(CONST_FILTER_ROMANEIO_COLHEITA, JSON.stringify(newFilter));

      // Disparando todas as ações após receber os dados
      yield put({ type: NEW_FILTER_ROMANEIO_COLHEITA_SUCCESS, list, filter: newFilter });
    } else {
      yield put({ type: NEW_FILTER_ROMANEIO_COLHEITA_FAILURE })
    }
  } catch (e) {
    yield put({ type: NEW_FILTER_ROMANEIO_COLHEITA_FAILURE })
  }
}

export default function* watchNewFilterPlanejamento() {
  yield takeLatest(NEW_FILTER_ROMANEIO_COLHEITA, sagaNewFilterPlanejamento)
}
