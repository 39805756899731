import * as Yup from 'yup';

const newRegistro = {
    status: true,
    nome: '',
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
});

export const centroCustoCadastro = {
    newRegistro,
    validation
}