import { takeLatest, put } from 'redux-saga/effects';

import {
  centroCustoCadastro,
  NEW_CENTRO_CUSTO_CADASTRO,
  NEW_CENTRO_CUSTO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewCentroCustoCadastro(action) {

  yield put({
    type: NEW_CENTRO_CUSTO_CADASTRO_SUCCESS,
    registro: centroCustoCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
    status: true,
  });
}

export default function* watchNewCentroCustoCadastro() {
  yield takeLatest(NEW_CENTRO_CUSTO_CADASTRO, sagaNewCentroCustoCadastro)
}
