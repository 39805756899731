import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_COLHEITA_SUCCESS,
    TABLE_COLHEITA_PENDING,
    TABLE_COLHEITA_SUCCESS,
    TABLE_COLHEITA_FAILURE,
    LIST_COLHEITA_PENDING,
    LIST_COLHEITA_SUCCESS,
    LIST_COLHEITA_FAILURE,
    FIND_COLHEITA_PENDING,
    FIND_COLHEITA_SUCCESS,
    FIND_COLHEITA_FAILURE,
    DELETE_COLHEITA_PENDING,
    DELETE_COLHEITA_SUCCESS,
    DELETE_COLHEITA_FAILURE,
    CREATE_COLHEITA_PENDING,
    CREATE_COLHEITA_SUCCESS,
    CREATE_COLHEITA_FAILURE,
    NEW_FILTER_NEXT_PAGE_COLHEITA_PENDING,
    NEW_FILTER_NEXT_PAGE_COLHEITA_SUCCESS,
    NEW_FILTER_NEXT_PAGE_COLHEITA_FAILURE,
    NEW_FILTER_COLHEITA_PEDING,
    NEW_FILTER_COLHEITA_SUCCESS,
    NEW_FILTER_COLHEITA_FAILURE,
    GET_TOTAIS_COLHEITA_PENDING,
    GET_TOTAIS_COLHEITA_SUCCESS,
    GET_TOTAIS_COLHEITA_FAILURE,
    GET_LOADING_COLHEITA_SUCCESS,
    GET_LOADING_COLHEITA_PENDING,
    GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_PENDING,
    GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_SUCCESS,
    GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_FAILURE,
    GET_GRAFICO_TOTAL_COLHIDO_PENDING,
    GET_GRAFICO_TOTAL_COLHIDO_SUCCESS,
    GET_GRAFICO_TOTAL_COLHIDO_FAILURE,
    GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_PENDING,
    GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_SUCCESS,
    GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_FAILURE
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    loadingTotais: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
    totais: {
        totalProducao: 0,
        totalProdutividade: 0
    },
    loadingGraficoAreaColhidaPorTalhao: false,
    graficoDataAreaColhidaPorTalhao: [],
    loadingGraficoTotalColhido: false,
    totalColhido: {
        totalAreaColhida: 0,
        totalAreaFaltante: 0,
        percentualTotalColhido: 0,
    },
    loadingGraficoProdutividadePorTalhao: false,
    graficoProdutividadePorTalhao: [],
}


function colheitaReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_COLHEITA_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_COLHEITA_PENDING:
        case FIND_COLHEITA_PENDING:
        case DELETE_COLHEITA_PENDING:
        case CREATE_COLHEITA_PENDING:
        case TABLE_COLHEITA_PENDING:
        case NEW_FILTER_NEXT_PAGE_COLHEITA_PENDING:
        case NEW_FILTER_COLHEITA_PEDING:
        case GET_LOADING_COLHEITA_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_LOADING_COLHEITA_SUCCESS:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_COLHEITA_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_COLHEITA_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_COLHEITA_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_COLHEITA_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_COLHEITA_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_COLHEITA_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_COLHEITA_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_COLHEITA_SUCCESS:
        case CREATE_COLHEITA_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_COLHEITA_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_COLHEITA_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_COLHEITA_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_COLHEITA_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_COLHEITA_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_TOTAIS_COLHEITA_PENDING:
            return {
                ...state,
                loadingTotais: true,
            }
        case GET_TOTAIS_COLHEITA_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                loadingTotais: false
            }
        case GET_TOTAIS_COLHEITA_FAILURE:
            return {
                ...state,
                totais: {
                    totalProducao: 0,
                    totalProdutividade: 0
                },
                loadingTotais: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_PENDING:
            return {
                ...state,
                loadingGraficoAreaColhidaPorTalhao: true,
            };
        case GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_SUCCESS:
            return {
                ...state,
                graficoDataAreaColhidaPorTalhao: action.graficoDataAreaColhidaPorTalhao,
                loadingGraficoAreaColhidaPorTalhao: false,
            };
        case GET_GRAFICO_COLHEITA_AREA_COLHIDA_POR_TALHAO_FAILURE:
            return {
                ...state,
                graficoDataAreaColhidaPorTalhao: [],
                loadingGraficoAreaColhidaPorTalhao: false,
            };
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_GRAFICO_TOTAL_COLHIDO_PENDING:
            return {
                ...state,
                loadingGraficoTotalColhido: true,
            };
        case GET_GRAFICO_TOTAL_COLHIDO_SUCCESS:
            return {
                ...state,
                totalColhido: action.totalColhido,
                loadingGraficoTotalColhido: false,
            };
        case GET_GRAFICO_TOTAL_COLHIDO_FAILURE:
            return {
                ...state,
                totaisColheita: { totalAreaColhida: 0, totalAreaFaltante: 0, percentualTotalColhido: 0 },
                loadingGraficoTotalColhido: false,
            };
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_PENDING:
            return {
                ...state,
                loadingGraficoProdutividadePorTalhao: true,
            };

        case GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_SUCCESS:
            return {
                ...state,
                graficoProdutividadePorTalhao: action.graficoProdutividadePorTalhao,
                loadingGraficoProdutividadePorTalhao: false,
            };

        case GET_GRAFICO_PRODUTIVIDADE_POR_TALHAO_FAILURE:
            return {
                ...state,
                graficoProdutividadePorTalhao: [],
                loadingGraficoProdutividadePorTalhao: false,
            };
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { colheitaReducer };